import * as React from "react";

function FullSpine(props) {
  return (
    <g id="fullSpine">
      <defs id="defs793" />
      <path
         d="m 54.862371,54.089879 c 0.254109,0.206125 0.381132,0.537377 0.471418,0.850228 0.274115,0.960649 1.019516,3.651225 -0.578355,3.732193 -2.43709,0.125165 -4.880766,0.08833 -7.314438,-0.110407 -0.18722,-0.01467 -0.384461,-0.03296 -0.541627,-0.132511 -0.524789,-0.327556 -0.173817,-1.126271 -0.437929,-1.663646 -0.207227,-0.423275 -0.785547,-0.592592 -1.273625,-0.500577 -0.488078,0.09205 -0.899264,0.386474 -1.277024,0.691958 -0.37775,0.305514 -0.74548,0.629405 -1.20346,0.813435 -0.404503,0.158262 -0.919324,0.172967 -1.230221,-0.110422 -0.310889,-0.287091 -0.193895,-0.909123 0.240706,-0.956969 0.137062,-0.01467 0.277479,0.02589 0.414523,0.02589 0.434592,-0.0076 0.722093,-0.412234 0.929345,-0.769261 0.451315,-0.769252 0.932701,-1.556921 1.694903,-2.072208 0.762194,-0.515297 1.875351,-0.669887 2.617537,-0.136175 0.528216,0.382787 0.798985,1.052658 1.410731,1.302949 0.715365,0.290772 1.511012,-0.128837 2.213044,-0.445362 0.882525,-0.393834 1.841995,-0.640438 2.814814,-0.721412 0.310923,-0.02589 0.641809,-0.02965 0.91257,0.117797 0.04679,0.02213 0.09358,0.05155 0.137106,0.08464 z"
         id="C_01"/>
      <path
         d="m 42.085563,61.105654 c -0.02004,0.02589 -0.03682,0.05154 -0.05015,0.08096 -0.240706,0.463761 0.247372,0.997457 0.718737,1.137319 0.899265,0.264966 1.865374,-0.08833 2.64429,-0.644118 0.347679,-0.250261 0.68531,-0.544728 1.086493,-0.65515 0.401103,-0.114094 0.902584,0.01836 1.079739,0.434321 0.20056,0.478481 -0.110275,1.082112 0.100263,1.556921 0.157183,0.34969 0.534881,0.49317 0.879196,0.588926 2.005817,0.574138 4.085189,0.846525 6.157807,0.80971 0.404529,-0.0076 0.822381,-0.02965 1.196768,-0.206086 1.400736,-0.662549 0.628495,-2.745784 -0.511476,-3.191144 -0.802312,-0.316532 -1.6815,-0.161935 -2.527278,-0.03672 -0.842467,0.121469 -1.751717,0.198772 -2.497233,-0.2503 -0.808979,-0.48217 -1.280299,-1.490667 -2.092695,-1.965476 -0.641818,-0.375426 -1.457542,-0.353362 -2.082647,0.05154 -0.972811,0.62939 -1.551149,2.109017 -2.677726,2.131104 -0.498108,0.0039 -1.133278,-0.231884 -1.424106,0.158262 z"
         id="C_02"/>
      <path id="C02D" d="M55.6400146,66.6900024c-0.0300293-0.0499878-0.0599976-0.0900269-0.1000366-0.1300049
		   c-0.3499756-0.3900146-0.8800049-0.4799805-1.3699951-0.5c-0.8099976-0.0299683-1.6099854,0.0900269-2.4199829,0.0300293
		   c-0.7999878-0.0599976-1.6300049-0.3200073-2.1799927-0.9700317c-0.4500122-0.5299683-0.710022-1.3200073-1.3099976-1.6300049
		   l0.0200195-0.0100098C50.210022,64.039978,52.2299805,64.2199707,54.25,64.2199707c0.4000244,0,0.7999878,0,1.2000122-0.1999512
		   c0.0999756,0,0.2000122-0.1000366,0.2000122-0.1000366c0.0499878-0.0299683,0.0999756-0.0700073,0.1499634-0.1199951h0.0200195
		   C55.8200073,63.7999878,56.6400146,65.3400269,55.6400146,66.6900024z"/>
      <path
         d="m 41.273168,65.231518 c 0.06683,-0.02212 0.143745,-0.01836 0.213963,-0.01467 1.414077,0.08832 2.891679,-0.04049 4.121891,-0.813482 0.41787,-0.261293 0.798967,-0.592597 1.233559,-0.813381 0.434601,-0.220898 0.952733,-0.327564 1.393991,-0.114125 0.615172,0.301865 0.87253,1.100549 1.330528,1.645224 0.548292,0.647868 1.383996,0.912811 2.186309,0.97176 0.802313,0.05885 1.607954,-0.05893 2.413604,-0.02965 0.494815,0.01467 1.02635,0.106773 1.374011,0.49317 0.461318,0.50796 0.508138,1.483383 0.357718,2.138495 -0.257446,1.129928 -1.290471,1.071079 -2.136169,1.045281 -1.3272,-0.04049 -2.624248,-0.03296 -3.921376,-0.404858 -0.554861,-0.158262 -1.103144,-0.379054 -1.574474,-0.732414 -0.471409,-0.353355 -0.872521,-0.853962 -1.05643,-1.450232 -0.0535,-0.176647 -0.09692,-0.375389 -0.227295,-0.496843 -0.130432,-0.121432 -0.314261,-0.1288 -0.484732,-0.132572 -1.604643,-0.03679 -3.26611,-0.02589 -4.890795,0.02965 -0.133699,0.0039 -0.274116,0.0076 -0.387781,-0.06991 -0.07019,-0.04779 -0.123704,-0.125104 -0.163814,-0.206085 -0.123712,-0.264928 -0.113674,-0.599944 0.02684,-0.850198 0.04008,-0.06991 0.09026,-0.136144 0.157113,-0.169301 0.01333,-0.01467 0.02349,-0.02213 0.03337,-0.02589 z"
         id="C_03"/>
      <path id="C03D" d="M55.4099731,72.6400146l-0.0099487,0.0100098
		   c-0.0400391-0.0300293-0.0700073-0.0599976-0.1100464-0.0800171c-0.5499878-0.3300171-1.2099609-0.3699951-1.8499756-0.4000244
		   c-0.5900269-0.0299683-1.1799927-0.0599976-1.7700195-0.0899658c-0.3499756-0.0100098-0.7299805-0.0400391-1.0299683-0.2600098
		   c-0.4500122-0.3400269-0.5700073-1.0100098-0.7900391-1.5599976c-0.2299805-0.5800171-0.6300049-1.0800171-1.1399536-1.3599854
		   l0.0099487-0.0200195c0.3500366,0.210022,0.7400513,0.3499756,1.1200562,0.460022
		   c1.2999878,0.3699951,2.5999756,0.3599854,3.9199829,0.3999634c0.6099854,0.0200195,1.3099976,0.0499878,1.75-0.3499756h0.0200195
		   C55.5300293,69.3900146,56.5599976,71.1199951,55.4099731,72.6400146z"/>
      <path
         d="m 42.383084,70.932158 c -0.167151,0.09575 -0.300858,0.254017 -0.334294,0.452783 -0.04344,0.257622 0.0936,0.52264 0.287483,0.673581 0.193885,0.15084 0.437929,0.20975 0.671934,0.242908 1.200132,0.161935 2.406947,-0.261362 3.61039,-0.184068 0.397872,0.02589 0.842458,0.143612 1.039681,0.522642 0.167152,0.323883 0.09696,0.725169 0.170489,1.085868 0.08697,0.42322 0.367721,0.776575 0.705361,1.004803 0.337667,0.228211 0.725447,0.34226 1.113228,0.423212 1.300377,0.279739 2.640987,0.235563 3.961432,0.368135 0.521461,0.05148 1.056334,0.132471 1.557823,-0.02589 1.320437,-0.415875 1.226831,-2.278311 0.127032,-2.926077 -0.558278,-0.32768 -1.216845,-0.368144 -1.848669,-0.397515 -0.591669,-0.02965 -1.180019,-0.05893 -1.771777,-0.08842 -0.357735,-0.01836 -0.732104,-0.0441 -1.029607,-0.261247 -0.458007,-0.338665 -0.57169,-1.008474 -0.792319,-1.560585 -0.260686,-0.647875 -0.732104,-1.203659 -1.333856,-1.457584 -0.601673,-0.253941 -1.327111,-0.169303 -1.821935,0.283418 -0.434548,0.393843 -0.661869,1.019584 -1.099798,1.409754 -0.591705,0.526312 -1.437483,0.52264 -2.189647,0.393741 -0.264112,-0.0441 -0.531535,-0.102985 -0.792283,-0.04779 -0.0769,0.01467 -0.157122,0.0441 -0.230668,0.08833 z"
         id="C_04"/>
      <path id="C04D" d="M55.4699707,78.3800049c-0.1499634-0.1900024-0.3499756-0.3599854-0.5999756-0.4899902
		   c-0.6699829-0.3599854-1.4400024-0.3800049-2.1799927-0.4700317c-0.7399902-0.0899658-1.5200195-0.2699585-2.039978-0.8699951
		   c-0.3300171-0.3900146-0.5400391-0.9299927-0.8099976-1.3699951c1.2399902,0.25,2.5099487,0.2200317,3.7699585,0.3400269
		   c0.5200195,0.0499878,1.0599976,0.1300049,1.5599976-0.0200195c0.1500244-0.0499878,0.2900391-0.1199951,0.4000244-0.2000122
		   C55.5700073,75.2999878,56.2999878,76.9500122,55.4699707,78.3800049z"/>
      <path
         d="m 42.513463,76.347219 c -0.09025,0.06623 -0.160442,0.161935 -0.193895,0.290734 -0.133698,0.507952 0.457998,0.853868 0.929354,0.942281 0.785599,0.14716 1.591267,0.176638 2.383541,0.08463 0.320927,-0.03679 0.648502,-0.09207 0.9695,-0.02212 0.317554,0.06999 0.631824,0.29452 0.715355,0.640431 0.106929,0.445338 -0.187219,0.91281 -0.09029,1.361828 0.140417,0.644195 0.912676,0.758312 1.514428,0.795027 0.87253,0.05163 1.741635,0.228213 2.574098,0.526413 0.879188,0.312783 1.748301,0.769222 2.670962,0.728742 1.875475,-0.0773 2.701202,-2.841446 0.879276,-3.809525 -0.668632,-0.357034 -1.437554,-0.375387 -2.176315,-0.4637 -0.738761,-0.08841 -1.521006,-0.276066 -2.035898,-0.86865 -0.598335,-0.692035 -0.802312,-1.88824 -1.641354,-2.145847 -0.681955,-0.209758 -1.330528,0.357042 -1.925535,0.776575 -1.069788,0.75454 -2.360171,1.115239 -3.627156,1.01591 -0.314261,-0.02589 -0.70537,-0.02965 -0.946067,0.147253 z"
         id="C_05"/>
      <path id="C05D" d="M54.8800049,84.0499878l-0.0100098,0.0100098
		   c-0.6699829-0.7199707-1.7199707-0.8499756-2.6900024-0.9199829c-1.0299683-0.0599976-2.1599731-0.1500244-2.9099731-0.9299927
		   c-0.4899902-0.5200195-0.7800293-1.3000488-1.2000122-1.8600464c0.2199707,0.0500488,0.4500122,0.0800171,0.6699829,0.0900269
		   c0.8699951,0.0499878,1.7399902,0.2299805,2.5800171,0.5299683c0.8699951,0.3100586,1.7399902,0.7700195,2.6699829,0.7300415
		   c0.3900146-0.0200195,0.7399902-0.1500244,1.0300293-0.3699951C55.0200195,81.3300171,55.8900146,82.75,54.8800049,84.0499878z"/>
      <path
         d="m 39.568288,81.882538 c -0.143754,0.121439 -0.254082,0.283381 -0.294192,0.478488 -0.270778,1.369165 3.914621,0.975324 4.603277,0.923827 0.40784,-0.02965 0.879205,-0.04779 1.149983,0.290733 0.447959,0.563228 -0.0936,1.49072 0.180519,2.17531 0.137071,0.338665 0.457989,0.552112 0.785599,0.636751 0.327628,0.08464 0.668615,0.06261 1.006264,0.06261 1.032936,-0.0076 2.069289,0.165622 3.052085,0.511524 1.002953,0.35337 1.975763,0.887112 3.025439,0.934944 0.528127,0.02589 1.076411,-0.08464 1.520997,-0.401186 0.952733,-0.677247 1.170034,-2.32257 0.434601,-3.268424 -0.671881,-0.864986 -1.815269,-1.019583 -2.848196,-1.085769 -1.032936,-0.0663 -2.162913,-0.147245 -2.915085,-0.931272 -0.72211,-0.754539 -0.989551,-2.064863 -1.922207,-2.440252 -0.481403,-0.19142 -1.02627,-0.0663 -1.49096,0.176608 -0.461326,0.242917 -0.872512,0.596278 -1.313779,0.879675 -1.019613,0.658884 -2.229775,0.960642 -3.406501,0.850297 -0.361037,-0.03296 -0.722083,-0.103094 -1.079774,-0.03296 -0.170489,0.04049 -0.34767,0.121462 -0.488079,0.239237 z"
         id="C_06"/>
      <path id="C06D" d="M53.7000122,90.6300049c0,0-0.0100098,0-0.0100098,0.0100098
		   c-0.3499756-1.2300415-3.0800171-1.210022-4.7600098-1.6799927c-1.7600098-0.4900513-1.5200195-0.3300171-2.0999756-2.1600342
		   c-0.0400391-0.1300049-0.0900269-0.2399902-0.1400146-0.3499756H47c1.0300293-0.0100098,2.0700073,0.1699829,3.0499878,0.5100098
		   c1,0.3599854,1.9800415,0.8899536,3.0300293,0.9400024c0.4500122,0.0199585,0.8999634-0.0599976,1.2999878-0.2800293h0.0100098
		   C54.3900146,87.6199951,55.0999756,89.7600098,53.7000122,90.6300049z"/>
      <path
         d="m 37.769768,87.771817 c 0,0 -0.608427,0.743523 -0.203924,1.229349 0.404493,0.485825 2.537317,0.485825 5.512563,0.445345 0,0 0.554932,-0.187747 0.344324,2.046411 -0.2106,2.234252 2.731211,1.266165 4.018265,1.564351 1.283716,0.298084 2.67105,2.009603 4.666793,1.376517 1.995744,-0.633087 1.758375,-2.197344 1.624694,-3.610668 -0.13376,-1.413425 -3.045507,-1.376611 -4.803882,-1.862435 -1.758374,-0.485834 -1.521006,-0.334994 -2.096024,-2.160529 -0.575,-1.825628 -2.741241,-1.527543 -3.246032,-0.820827 -0.508138,0.706708 -1.962335,1.711511 -3.550247,1.674696 -1.591258,-0.02965 -2.025841,-0.287053 -2.266539,0.117797 z"
         id="C_07"/>
      <path id="C07D" d="M52.3400269,94.3499756c0,0,0.0599976,2.2300415-1.5599976,3.2300415
		   c0,0-0.0100098,0-0.0100098,0.0100098c-0.2200317-0.8200073-0.7000122-1.5200195-1.4299927-1.8099976
		   c-0.6800537-0.2700195-1.4300537-0.1800537-2.1600342-0.1900024c-0.7299805-0.0100098-1.5200195-0.1700439-1.9899902-0.7800293
		   c-0.4099731-0.539978-0.4799805-1.3200073-0.8300171-1.8900146c0.9200439,0.2399902,2.2999878-0.039978,3.0800171,0.1400146
		   c1.2799683,0.2999878,2.6699829,2.0100098,4.6699829,1.3699951C52.1900024,94.4000244,52.2700195,94.3800049,52.3400269,94.3499756
		   z"/>
      <path
         d="m 38.959543,93.126661 c -0.755501,0.493217 -1.497679,1.085793 -2.363464,1.118913 -0.521549,0.01836 -1.046435,-0.169295 -1.56123,-0.08463 -0.518133,0.08463 -1.039603,0.640439 -0.849125,1.174127 0.173818,0.474809 0.742187,0.559471 1.210179,0.563151 1.721646,0.02212 3.443292,0.04786 5.164937,0.06991 -0.531543,0.739812 -0.885932,1.630527 -1.019613,2.561741 -0.03682,0.268682 -0.05686,0.552103 0.03673,0.806055 0.147083,0.397521 0.524887,0.618365 0.88927,0.772942 2.002409,0.85392 4.245525,0.64779 6.324888,1.22199 0.916004,0.25394 1.908883,0.65149 2.757919,0.19878 1.908875,-1.02325 1.821917,-4.96155 -0.207226,-5.752897 -0.685302,-0.268677 -1.434137,-0.176639 -2.162913,-0.18771 -0.725438,-0.01091 -1.521094,-0.169332 -1.989086,-0.78398 -0.447924,-0.588911 -0.48806,-1.461227 -0.939401,-2.046449 -0.477987,-0.614671 -1.293711,-0.754541 -2.029144,-0.721421 -1.043019,0.04787 -2.075955,0.368066 -2.991959,0.92017 -0.09029,0.05154 -0.180554,0.110414 -0.270778,0.169332 z"
         id="T_01"/>
      <path id="T01D" d="M49.1199951,104.710022c-0.0100098-0.0400391-0.0100098-0.0700073-0.0200195-0.1100464
		   c-0.0699463-0.2799683-0.3199463-0.4699707-0.5599976-0.6199951c-1.0299683-0.6199951-2.1300049-0.9599609-3.2699585-1.2199707
		   c-0.960022-0.2299805-1.9299927-0.3400269-2.9000244-0.4799805c-0.1500244-0.0200195-0.2999878-0.0400391-0.4199829-0.1200562
		   c-0.4299927-0.2999878-0.5-1.3099976-0.7800293-1.789978c1.8099976,0.5499878,3.7800293,0.460022,5.6199951,0.960022
		   c0.9200439,0.25,1.9100342,0.6499634,2.7600098,0.2000122C49.5499878,101.5300293,50.4699707,102.8900146,49.1199951,104.710022z"
		/>
      <path
         d="m 37.298032,105.37641 c 0.07019,0.0551 0.143746,0.10652 0.217301,0.14701 2.75467,1.57169 5.372154,3.71385 8.614894,3.839 0.685293,0.0262 1.424054,-0.15115 1.888806,-0.69202 0.300823,-0.34955 0.45791,-0.8134 0.585012,-1.26974 0.220638,-0.77668 0.384373,-1.57172 0.48807,-2.37777 0.01669,-0.13989 0.03337,-0.28353 0.0035,-0.41963 -0.06692,-0.2871 -0.317563,-0.47482 -0.558278,-0.61831 -1.022942,-0.62205 -2.129513,-0.96063 -3.269474,-1.22567 -0.956062,-0.22457 -1.932192,-0.33859 -2.901674,-0.47481 -0.143746,-0.0221 -0.294184,-0.0442 -0.414515,-0.1288 -0.427935,-0.29812 -0.498152,-1.30664 -0.782235,-1.78144 -0.374475,-0.625724 -0.855791,-1.104208 -1.571243,-1.199901 -1.841995,-0.246597 -6.097593,1.236701 -7.936171,2.473411 -1.842003,1.24035 1.210082,1.31029 2.009066,1.00478 0.75883,-0.28707 4.26901,-1.72988 3.941356,0.3975 -0.06675,0.43067 -0.320892,0.79502 -0.511476,1.18153 -0.08688,0.17665 -0.163743,0.37171 -0.147074,0.57048 0.03028,0.23927 0.167152,0.42698 0.344315,0.57422 z"
         id="T_02"/>
      <path id="T02D" d="M46.1900024,112.789978h-0.0100098c-0.0200195-0.1099854-0.0599976-0.2099609-0.1300049-0.2999878
		   c-0.1300049-0.1799927-0.3200073-0.289978-0.5100098-0.3900146c-0.9099731-0.4799805-1.8200073-0.9599609-2.7399902-1.4400024
		   c-0.8800049-0.4599609-2.4099731-0.8499756-3.1199951-1.5599976c-0.7000122-0.6900024-0.5599976-2.1799927-1.2299805-3.0299683
		   c2.4199829,1.4899902,4.8099976,3.1799927,7.6799927,3.289978c0.5100098,0.0200195,1.039978-0.0700073,1.4699707-0.3399658
		   c0.0100098,0,0.0100098,0,0.0100098,0S47.8400269,111.6400146,46.1900024,112.789978z"/>
      <path
         d="m 34.092198,113.00568 c 0.371049,0.59261 1.477523,0.92015 2.012404,1.21824 1.912203,1.05637 3.566968,2.66857 5.64634,3.35685 2.684374,0.88701 4.416005,-1.23303 4.49288,-4.18494 0.01024,-0.31654 -0.01333,-0.65518 -0.190487,-0.90547 -0.130431,-0.18038 -0.327655,-0.28689 -0.514883,-0.38646 -0.912588,-0.48221 -1.821838,-0.96435 -2.737842,-1.43916 -0.882516,-0.46003 -2.406939,-0.85017 -3.119063,-1.56059 -0.939313,-0.93482 -0.360958,-3.28688 -2.266512,-3.61808 -3.225903,-0.55948 -7.578541,1.48703 -9.965403,3.75423 -0.06683,0.0627 -0.137009,0.13277 -0.163752,0.22841 -0.02693,0.0919 -0.0071,0.19127 0.02993,0.27978 0.401112,1.04528 1.972346,0.68092 2.717862,0.47481 1.203416,-0.33117 2.564015,-0.90179 3.824326,-0.76187 2.443667,0.27228 -0.381123,2.34456 0.193895,3.47085 0.01333,0.03 0.02693,0.0514 0.04017,0.0735 z"
         id="T_03"/>
      <path id="T03D" d="M42.8699951,121.25c0,0-1.5800171-2.7999878-5.9400024-3.6199951
		   c0,0,0-2.2299805-1.2199707-3.5999756c0.1499634,0.0699463,0.2799683,0.1299438,0.3899536,0.1899414
		   c1.9200439,1.0600586,3.5700073,2.6700439,5.6500244,3.3600464c1.0100098,0.3399658,1.8900146,0.2399902,2.5900269-0.1600342
		   C44.3400269,117.4199829,44.7299805,120.4500122,42.8699951,121.25z"/>
      <path
         d="m 30.986556,123.23839 c 0,0 6.57558,3.73591 9.865131,3.9714 0,0 2.226367,-0.69927 2.01582,-5.95528 0,0 -1.577891,-2.80095 -5.93377,-3.62176 0,0 -0.0071,-3.85371 -2.764664,-4.55297 -2.757999,-0.69937 -9.333605,5.37378 -9.333605,7.06686 0,1.69312 1.908812,-0.03 1.908812,-0.03 0,0 3.714061,-3.06599 4.984366,-2.7163 1.270393,0.34953 -2.112764,5.09771 -0.74209,5.83754 z"
         id="T_04"/>
      <path id="T04D" d="M39.8099976,130.6099854h-0.0100098c-0.7199707-2.1900024-3.3200073-2.1400146-5.0899658-3.2699585
		   c-1.2000122-0.7600098-1.4900513-2.2700195-2.2300415-3.3000488c2.1300049,1.1100464,6.0599976,3,8.3699951,3.1700439
		   C40.8499756,127.210022,41.7800293,129.1799927,39.8099976,130.6099854z"/>
      <path
         d="m 28.1082,132.53501 c 0.03682,-0.3827 0.103697,-0.76552 0.150517,-1.14089 0.08017,-0.63679 0.0769,-1.3325 -0.257446,-1.85879 -0.447915,-0.69936 -1.370576,-0.85762 -2.1094,-0.5889 -0.735459,0.27227 -1.323826,0.8833 -1.865391,1.49437 -0.541556,0.61462 -1.076437,1.26238 -1.771777,1.65259 -0.297521,0.16913 -0.638499,0.28353 -0.969455,0.21338 -0.638508,-0.13983 -0.99287,-0.96797 -0.895919,-1.67469 0.17048,-1.24037 1.200132,-2.09791 2.069299,-2.76413 1.313788,-1.00481 2.624238,-2.00963 3.938017,-3.01819 1.203523,-0.92384 2.554031,-1.91762 4.085119,-1.86608 2.70777,0.0885 2.34005,3.15808 4.225527,4.35794 2.052549,1.31034 5.208332,1.03796 5.295289,4.48302 0.02684,1.07107 0.07355,4.73335 -1.069753,5.28544 -0.33433,0.16206 -0.715444,0.13611 -1.079836,0.10684 -1.841906,-0.15449 -3.690655,-0.32777 -5.492504,-0.7877 -1.343851,-0.34241 -3.576972,-0.81713 -4.13525,-2.45134 -0.157086,-0.46738 -0.163743,-0.95698 -0.117029,-1.44281 z"
         id="T_05"/>
      <path id="T05D" d="M38.1799927,141.9099731h-0.0100098C38,141.4899902,37.710022,141.0999756,37.2399902,140.7700195
		   c-1.6199951-1.1700439-5.0899658-2.2900391-7-2.4100342c0,0,0.2399902-1.3900146-0.1499634-2.6300049
		   c0.7799683,0.3400269,1.6299438,0.5400391,2.2699585,0.7000122c1.7999878,0.460022,3.6500244,0.6300049,5.4899902,0.789978
		   c0.3699951,0.0300293,0.75,0.0500488,1.0800171-0.1099854c0.0599976-0.0299683,0.1199951-0.0700073,0.1799927-0.1199951h0.0100098
		   C39.1199951,136.9899902,40.8099976,140.1599731,38.1799927,141.9099731z"/>
      <path
         d="m 25.607691,139.67214 c 0.437929,1.57904 0.180519,4.42045 0.180519,5.57252 0,0.0918 0.0035,0.19128 0.05686,0.26141 0.04679,0.0626 0.12365,0.0848 0.197223,0.10686 1.250289,0.34242 10.86796,3.41569 11.362784,1.66368 0.514804,-2.38874 1.985749,-4.95787 -0.16385,-6.50735 -1.624702,-1.17053 -5.09464,-2.29313 -7.003514,-2.41088 0,0 0.685284,-4.01565 -2.166241,-4.51992 -2.851578,-0.50417 -6.04077,0.50061 -8.320676,5.52105 -2.279914,5.0204 -1.594595,5.27066 -1.253609,5.27066 0.340978,0 1.480922,-0.6293 4.446147,-5.02044 1.581229,-1.61207 2.330055,-1.14827 2.664349,0.0626 z"
         id="T_06"/>
      <path id="T06D" d="M37.4699707,150.5700073c-0.0199585-0.039978-0.0299683-0.0800171-0.0499878-0.1099854
		   c-0.4099731-0.7800293-6.4500122-1-6.4500122-1S30.789978,147.7299805,29.1300049,146.5
		   c3.0599976,0.8499756,7.3200073,1.8400269,8.1500244,0.9899902
		   C37.2800293,147.4899902,38.9699707,149.0599976,37.4699707,150.5700073z"/>
      <path
         d="m 25.731404,157.0006 v -5.32229 c 0,0 -1.310442,-0.22092 -1.711599,0 -0.401156,0.22091 -3.423214,5.54681 -4.028295,5.32229 -0.60508,-0.22091 -2.1161,-0.75823 -1.310441,-2.70896 0.805659,-1.95079 5.138149,-9.71329 8.561355,-8.71586 3.423223,0.99748 3.727383,3.88314 3.727383,3.88314 0,0 6.044133,0.22092 6.445334,0.99747 0.404441,0.77656 1.210082,7.21041 0,7.43122 -1.210179,0.22465 -11.078657,1.88456 -11.683737,-0.88701 z"
         id="T_07"/>
      <path id="T07D" d="M37.5800171,161.8900146c-0.1500244-0.25-0.3500366-0.4800415-0.6199951-0.6300049
		   c-0.3400269-0.2000122-0.75-0.2800293-1.1400146-0.3400269c-1.9500122-0.2999878-3.9400024-0.3800049-5.9199829-0.2399902
		   c-0.3500366-0.7299805-0.8300171-1.4299927-1.5-1.9400024C28.210022,158.5999756,28,158.4699707,27.7800293,158.3599854
		   c3.1099854,0.6400146,8.7399902-0.3099976,9.6399536-0.4699707c0.0300293,0,0.0499878-0.0100098,0.0700073-0.0300293
		   C37.4899902,157.8599854,39.1500244,159.7800293,37.5800171,161.8900146z"/>
      <path
         d="m 24.728477,163.87284 c 0.0099,0.0187 0.02004,0.0404 0.02993,0.0627 0.03673,0.0994 0.04008,0.20253 0.04344,0.30941 0.02693,1.07843 0.0535,2.15686 0.08026,3.23896 0.01669,0.69933 -0.521505,2.28198 0.394472,2.62432 0.748818,0.28353 2.169579,0.0701 2.991969,0.0848 1.755037,0.0337 3.510083,0.0479 5.265217,-0.0514 0.95939,-0.0551 1.928863,-0.14742 2.834785,-0.4527 0.347644,-0.11777 0.698704,-0.27604 0.909347,-0.55578 0.157069,-0.21001 0.223967,-0.46381 0.277409,-0.71404 0.407866,-1.82563 0.715364,-4.06712 0.277453,-5.89643 -0.120367,-0.50427 -0.401113,-0.99379 -0.872442,-1.26618 -0.337632,-0.19877 -0.745507,-0.27228 -1.143379,-0.33492 -1.948941,-0.29813 -3.938027,-0.37903 -5.913702,-0.23925 -0.357717,-0.73243 -0.829047,-1.43547 -1.501008,-1.94333 -0.67189,-0.50794 -1.561151,-0.81349 -2.426962,-0.6847 -0.698687,0.10305 -1.320481,0.46381 -1.885442,0.86132 -2.634269,1.84401 -4.459532,4.56774 -5.442364,7.47546 -0.116993,0.34954 -0.22733,0.71404 -0.173852,1.07475 0.05015,0.36078 0.300858,0.71771 0.681973,0.80604 0.361037,0.0811 0.745489,-0.0957 0.996207,-0.3533 0.250736,-0.25389 0.404494,-0.58145 0.564962,-0.89069 0.578328,-1.09686 1.373966,-2.098 2.333392,-2.94082 0.25071,-0.21716 0.524851,-0.43434 0.859137,-0.50429 0.307559,-0.0664 0.675298,0.0404 0.819043,0.29104 z"
         id="T_08"/>
      <path id="T08D" d="M37.1599731,172.710022c-0.0999756-0.1700439-0.2199707-0.3200073-0.3399658-0.460022
		   c-1.210022-1.3599854-3.0900269-0.6799927-4.6300049-0.6099854c-1.2200317,0.0499878-1.9199829-0.1500244-2.8800049-0.9400024
		   c-0.2199707-0.1900024-0.460022-0.3599854-0.7000122-0.5100098c1.6400146,0.039978,3.2800293,0.039978,4.9200439-0.0499878
		   c0.9599609-0.0499878,1.9299927-0.1500244,2.8399658-0.4500122c0.3099976-0.1099854,0.6300049-0.25,0.8300171-0.4799805h0.0100098
		   C37.210022,169.210022,38.6699829,170.9699707,37.1599731,172.710022z"/>
      <path
         d="m 38.234105,179.87974 c -0.177146,0.27977 -0.504809,0.43064 -0.812395,0.54099 -1.788438,0.64046 -3.570218,0.26515 -5.38215,0.59629 -1.791854,0.32776 -3.613789,0.40115 -5.422294,0.195 -0.150438,-0.0187 -0.310889,-0.0403 -0.43793,-0.11776 -0.01669,-0.0111 -0.03673,-0.0225 -0.05677,-0.0338 -0.150438,-0.11404 -0.217291,-0.29064 -0.244034,-0.49317 -0.02004,-0.11777 -0.02004,-0.24678 -0.02004,-0.36453 0.01333,-0.67725 0.247372,-1.59008 0.304222,-2.43657 0.0535,-0.76191 -0.04679,-1.47595 -0.581675,-1.92504 -0.778916,-0.65145 -2.052593,-0.55215 -2.657674,0.29103 -0.51815,0.72506 -0.645182,1.64518 -1.099825,2.41074 -0.424571,0.71408 -0.999554,1.34347 -1.681518,1.82196 -0.100298,0.0701 -0.213963,0.14372 -0.340986,0.15114 -0.18722,0.0111 -0.294184,-0.10306 -0.337632,-0.30566 -0.234005,-1.01211 1.066407,-4.24751 1.283699,-4.64871 0.97281,-1.78504 1.878758,-3.7321 3.5469,-4.9688 0.929354,-0.69203 2.126131,-1.08956 3.246033,-0.8355 0.65523,0.14705 1.250228,0.50416 1.771786,0.93848 0.956062,0.795 1.654766,0.99746 2.878259,0.94229 1.537834,-0.0701 3.423223,-0.74718 4.633402,0.61463 1.357262,1.5312 1.290382,4.70386 1.51434,6.6473 0.03337,0.29065 0.06684,0.59994 -0.0535,0.86863 -0.0071,0.0404 -0.02693,0.0772 -0.05015,0.11065 z"
         id="T_09"/>
      <path id="T09D" d="M37.960022,182.9199829c-0.0500488-0.0700073-0.0900269-0.1399536-0.1400146-0.2099609
		   c-1.6000366-2.3300171-6.4500122,1.5700073-8.3300171,0.039978c-0.5999756-0.4899902-1.1799927-1.0300293-1.8699951-1.3800049
		   c-0.0700073-0.039978-0.1500244-0.0700073-0.2199707-0.0899658c1.5499878,0.1099854,3.1099854,0.0099487,4.6399536-0.2600098
		   c1.8099976-0.3300171,3.5900269,0.039978,5.3800049-0.6000366c0.2700195-0.0999756,0.5599976-0.2299805,0.7399902-0.4500122
		   C38.1599731,179.9699707,39.4500122,181.75,37.960022,182.9199829z"/>
      <path
         d="m 40.69785,190.71163 c -0.217301,0.6849 -0.785573,1.13341 -1.611292,1.26242 -1.236905,0.19539 -2.470401,0.37204 -3.697312,0.62217 0,0 -7.989745,3.29442 -8.337388,-0.29855 -0.01669,-0.17665 -0.01333,-0.36716 0.0071,-0.57754 0.03682,-0.34616 0.06021,-0.66236 0.07019,-0.9605 v -0.0187 c 0.127005,-3.61075 -1.424098,-4.12605 -2.059269,-4.13342 -0.919306,-0.0141 -1.648073,1.24038 -1.928881,2.02072 -0.488078,1.35447 -0.778924,2.65698 -1.527742,3.90528 -0.120331,0.19839 -0.257411,0.40485 -0.468019,0.4892 -0.334294,0.12481 -0.481385,-0.0923 -0.534872,-0.35705 -0.02693,-0.13612 -0.02349,-0.29065 -0.0099,-0.40782 0.180518,-1.56822 0.264112,-3.16197 0.67528,-4.6895 0.37776,-1.39495 1.012921,-2.71999 1.554486,-4.04865 0.137088,-0.33491 0.270786,-0.67355 0.484732,-0.95331 0.381097,-0.50061 0.966127,-0.77667 1.541109,-0.98276 0.929354,-0.33116 1.841942,-0.67724 2.764612,-0.20963 0.685381,0.34577 1.273731,0.887 1.872066,1.37649 1.88214,1.53119 6.726088,-2.37029 8.327385,-0.0403 1.113228,1.62323 2.480476,4.71498 2.901665,6.68043 0.107035,0.50063 0.09364,0.94595 -0.02349,1.32133 z"
         id="T_10"/>
      <path id="T10D" d="M40.789978,193.8599854c-0.5599976-0.3099976-1.2099609-0.4500122-2.0199585-0.3200073
		   c-1.7600098,0.2700195-4.4700317,2.0200195-6.1600342,0.7900391c-0.2799683-0.2000122-0.5499878-0.4200439-0.8299561-0.6199951
		   c1.8799438-0.4000244,3.6099854-1.1100464,3.6099854-1.1100464c1.2299805-0.25,2.4599609-0.4299927,3.7000122-0.6300049
		   c0.5099487-0.0699463,0.9299927-0.2799683,1.2199707-0.5899658
		   C40.3099976,191.3800049,41.4799805,192.4400024,40.789978,193.8599854z"/>
      <path
         d="m 44.859913,202.05575 c -0.531535,1.80795 -3.968169,1.53906 -5.793423,1.91019 0,0 -8.541269,3.36783 -8.959121,-0.17249 -0.02004,-0.17249 -0.02004,-0.36416 0,-0.57057 0.03673,-0.34243 0.05677,-0.65491 0.06348,-0.94962 v -0.0187 c 0.09029,-3.56729 -1.584557,-4.05252 -2.263175,-4.05252 -0.98955,0 -1.755054,1.24731 -2.049247,2.02033 -0.504783,1.34354 -0.802304,2.63552 -1.59125,3.87987 -0.123703,0.19839 -0.274106,0.40487 -0.494761,0.48919 -0.357691,0.12906 -0.518159,-0.0844 -0.578329,-0.34241 -0.02993,-0.13577 -0.02993,-0.2869 -0.01669,-0.40486 0.173817,-1.55295 0.244035,-3.12868 0.665242,-4.64097 0.38778,-1.38027 1.056377,-2.69804 1.618001,-4.0198 0.140409,-0.33154 0.284145,-0.66978 0.508138,-0.9496 0.404494,-0.50011 1.029652,-0.77994 1.644736,-0.99329 0.996208,-0.34243 1.969009,-0.69561 2.968545,-0.24715 0.738858,0.33152 1.380668,0.8583 2.029241,1.33262 1.691485,1.23345 4.399353,-0.51894 6.157727,-0.79085 3.326272,-0.51898 4.148662,3.35991 5.569378,6.06886 0.564944,1.08261 0.692047,1.87351 0.521558,2.45098 z"
         id="T_11"/>
      <path id="T11D" d="M45.8400269,204.5800171c-0.7600098-0.3800049-1.6700439-0.2399902-2.4800415,0
		   c-1.3699951,0.4199829-2.6599731,1.1199951-4.0100098,1.6199951c-1.3499756,0.4899902-2.8199463,0.7699585-4.1799927,0.3200073
		   c-0.9400024-0.3099976-1.7999878-0.960022-2.75-1.1000366c0-0.0100098,0.0100098-0.0199585,0.0100098-0.0199585
		   c2.6600342,0.1399536,6.6400146-1.4300537,6.6400146-1.4300537c1.710022-0.3599854,4.8599854-0.1399536,5.6699829-1.6199951
		   C44.7399902,202.3499756,46.3099976,202.8499756,45.8400269,204.5800171z"/>
      <path
         d="m 33.360094,211.26128 c -0.244035,-0.72833 -0.742178,-1.42788 -1.437554,-1.53803 -0.962719,-0.15077 -1.718229,0.8504 -2.259855,1.73748 -0.738761,1.20666 -1.480851,2.41726 -2.219701,3.62489 -0.227304,0.36829 -0.514804,0.77696 -0.919342,0.78391 -0.354327,0.004 -0.651848,-0.33154 -0.755474,-0.70654 -0.100289,-0.37507 -0.04679,-0.77693 0.02349,-1.15599 0.491407,-2.67524 1.735039,-5.17883 3.516811,-7.07412 0.75551,-0.80175 1.688157,-1.5311 2.73793,-1.53804 1.103145,-0.007 2.065873,0.77595 3.118965,1.12626 1.3606,0.45247 2.838211,0.1725 4.188719,-0.32442 1.350605,-0.49614 2.634322,-1.19967 4.001578,-1.61544 0.90925,-0.27605 1.942275,-0.40884 2.741259,0.14405 1.136545,0.77998 1.945612,3.78367 2.393527,5.12327 2.617573,7.86592 -5.609532,5.05671 -10.938213,8.77487 -0.691958,0.48223 -1.691582,0.0374 -2.38687,-0.43862 -0.825718,-0.56661 -1.190102,-2.66035 -1.337185,-3.72111 -0.143745,-1.05974 -0.133768,-2.15626 -0.461335,-3.16541 0,-0.0141 -0.0035,-0.0263 -0.0071,-0.0376 z"
         id="T_12"/>
      <path id="T12D" d="M50.4400024,216.7600098c-0.3099976-0.1600342-0.6599731-0.2399902-1.0300293-0.2600098
		   c-1.3899536-0.0999756-3.0699463,0.5900269-4.1900024,0.9500122c-1.0499878,0.3300171-2.0799561,0.7199707-3.0899658,1.1499634
		   c-0.8400269,0.3500366-1.7200317,0.9400024-2.6099854,1.1300049c-0.0200195,0-0.0400391,0.0100098-0.0700073,0.0100098
		   c-0.8200073,0.1600342-1.3599854-0.6099854-2.0300293-1.039978c0.0400391-0.0200195,0.0900269-0.0499878,0.1300049-0.0800171
		   c4.0400391-2.8099976,9.7399902-1.8800049,11.1300049-4.6900024
		   C48.6799927,213.9299927,50.6900024,214.8599854,50.4400024,216.7600098z"/>
      <path
         d="m 53.560467,225.31074 c -0.177155,1.64893 -1.260319,2.87833 -2.784654,2.97026 -1.203513,0.0734 -2.373538,0.0375 -3.566977,0.27605 -1.183436,0.24302 -2.336721,0.64041 -3.470026,1.08578 -1.280388,0.5006 -2.674379,1.46491 -4.034969,1.64165 -0.01669,0.004 -0.02993,0.004 -0.04679,0.007 -2.009164,0.24302 -1.862072,-2.65384 -2.039227,-4.10028 -0.09029,-0.72508 -0.277444,-1.88083 -0.992852,-2.09797 -0.113656,-0.0375 -0.237334,-0.0481 -0.377751,-0.03 -1.116547,0.13611 -1.420752,1.19246 -1.935582,2.09796 -0.247372,0.43797 -0.548248,0.85018 -0.892572,1.19986 -0.538218,0.54848 -1.200132,0.9423 -1.912176,1.03063 h -0.0099 c -0.257411,0.0299 -0.524851,0.0187 -0.792283,-0.0404 -0.61511,-0.13611 -1.176726,-0.52265 -1.564515,-1.06378 -0.106973,-0.15078 -0.200561,-0.31277 -0.280817,-0.48214 -0.812351,-1.78141 0.167143,-3.34197 1.370621,-4.4499 1.032971,-0.9496 1.484277,-2.39613 2.353452,-3.49295 0.855799,-1.08213 2.223073,-1.58266 3.520148,-1.498 h 0.0035 c 0.0071,-0.004 0.0099,0 0.01669,0 0.287482,0.0187 0.571645,0.0664 0.845822,0.13982 0.902584,0.25016 1.484277,1.42445 2.483813,1.23673 0.02349,-0.004 0.04335,-0.007 0.06675,-0.0141 0.885942,-0.18413 1.768458,-0.77664 2.610916,-1.12996 1.012956,-0.43065 2.042555,-0.81343 3.085574,-1.14837 1.123222,-0.35704 2.801385,-1.04533 4.188719,-0.94962 0.922661,0.059 1.714988,0.46748 2.142835,1.53484 0.899255,2.26362 2.293335,4.69655 2.012492,7.27668 z"
         id="L_01"/>
      <path id="L01D" d="M54.4099731,230.6699829c-0.9799805-0.9199829-2.4699707-1.0499878-3.7399902-0.7000122
		   c-1.3899536,0.3800049-2.6399536,1.210022-3.9500122,1.8400269c-1.3099976,0.6199951-2.7699585,1.0200195-4.1699829,0.5300293
		   c-0.7399902-0.2600098-1.3800049-0.8300171-2.0800171-1.2400513c1.1100464-0.3399658,2.2300415-1.0499878,3.2700195-1.4599609
		   c1.1300049-0.4400024,2.2900391-0.8400269,3.4700317-1.0800171c1.1900024-0.2399902,2.3599854-0.210022,3.5700073-0.2799683
		   c1.0599976-0.0600586,1.9099731-0.6800537,2.3799438-1.6100464
		   C53.1599731,226.6699829,55.3300171,228.5999756,54.4099731,230.6699829z"/>
      <path
         d="m 57.297942,240.40144 c -0.190557,0.94225 -0.966162,1.60849 -1.775141,1.97653 -3.941435,1.79248 -8.15355,1.81458 -12.33894,1.95073 -0.534872,0.0187 -1.096488,-0.0443 -1.537746,-0.37544 -0.230694,-0.17664 -0.411195,-0.4159 -0.548283,-0.68828 -0.15374,-0.29814 -0.254012,-0.63675 -0.314261,-0.97165 -0.173818,-0.94229 -0.127006,-1.93975 -0.33433,-2.87826 -0.0035,-0.0225 -0.0099,-0.0404 -0.01333,-0.0626 -0.06021,-0.26519 -0.157184,-0.51533 -0.314261,-0.72508 -0.407867,-0.54478 -1.163368,-0.6699 -1.758375,-0.40853 -1.002962,0.43802 -1.283716,1.63411 -1.992441,2.41811 -1.079783,1.19623 -2.991969,1.29565 -4.118554,0.11019 -0.203925,-0.21339 -0.371067,-0.46009 -0.501437,-0.72872 -0.554932,-1.13734 -0.464681,-2.66482 0.310888,-3.65494 0.628478,-0.80234 1.537763,-1.15939 2.299957,-1.75562 0.718746,-0.55951 1.243597,-1.32134 1.811896,-2.05389 0.78228,-1.01211 1.818545,-2.01692 3.022058,-1.91028 1.250236,0.11022 2.189647,1.28465 3.356343,1.69686 1.393991,0.49319 2.858279,0.0884 4.165313,-0.52997 1.313779,-0.62577 2.560775,-1.46128 3.954766,-1.84045 1.390654,-0.37538 3.028777,-0.19502 4.00157,0.96809 0.434601,0.52267 0.695287,1.18889 0.919341,1.85868 0.02349,0.0772 0.05015,0.15078 0.07355,0.2284 0.605002,1.87342 1.136554,3.77639 1.531001,5.71978 0.110363,0.54466 0.210634,1.11159 0.100368,1.65625 z"
         id="L_02"/>
      <path id="L02D" d="M58.6699829,245.0200195v0.0100098c-0.0799561-0.0700073-0.1799927-0.1400146-0.2799683-0.2000122
		   c-0.5800171-0.3400269-1.2700195-0.3699951-1.9200439-0.3099976c-2.0999756,0.1699829-4.0699463,1.1300049-6.0999756,1.789978
		   C49.7700195,246.5100098,49.1099854,246.6799927,48.5,246.5c-0.7199707-0.2199707-1.2399902-0.8900146-1.8599854-1.3499756
		   c-0.6900024-0.5200195-1.5200195-0.7700195-2.3500366-0.75l0.0200195-0.1100464
		   c3.8200073-0.1199951,7.6199951-0.2799683,11.210022-1.9099731c0.6699829-0.3099976,1.3200073-0.8200073,1.6300049-1.5200195
		   C57.1500244,240.8599854,59.4299927,242.9400024,58.6699829,245.0200195z"/>
      <path
         d="m 60.373416,256.8242 c 0.01315,0.64777 -0.100237,1.26986 -0.52479,1.69684 -0.5048,0.50784 -1.263648,0.52261 -1.949029,0.51165 -4.135241,-0.0811 -8.273828,-0.1621 -12.409078,-0.24304 -0.518133,-0.0111 -1.076411,-0.0337 -1.487606,-0.38274 -0.441257,-0.37548 -0.598423,-1.0306 -0.635152,-1.64156 -0.03682,-0.61109 0.02004,-1.23669 -0.117029,-1.83296 -0.254109,-1.09688 -1.216836,-1.93974 -2.243204,-1.96557 -0.839033,-0.0225 -1.624605,0.44546 -2.286581,1.00858 -0.661886,0.56312 -1.250236,1.2367 -1.948923,1.74458 -0.344332,0.24677 -0.72542,0.45646 -1.13326,0.4859 -0.979494,0.0697 -1.771777,-0.89444 -2.179626,-1.87723 -0.421216,-1.01947 -0.611755,-2.15317 -0.544902,-3.27208 0.06357,-1.05998 0.40784,-2.17888 1.206815,-2.77516 1.176726,-0.8723 2.774642,-0.28729 4.158638,-0.60738 1.868729,-0.43432 3.129049,-2.44392 4.950975,-3.07702 1.133295,-0.39382 2.423678,-0.18414 3.406474,0.54847 0.618412,0.46369 1.143299,1.12991 1.865409,1.35084 0.611738,0.18788 1.263639,0.0141 1.872057,-0.18417 2.022478,-0.65879 3.991496,-1.6231 6.097602,-1.79618 0.651901,-0.0551 1.337185,-0.0226 1.918869,0.31279 1.437474,0.82448 1.096479,3.26843 1.193439,4.73703 0.133654,2.03168 0.361055,4.05976 0.671873,6.06569 0.05695,0.38283 0.110371,0.79503 0.117029,1.19254 z"
         id="L_03"/>
      <path id="L03D" d="M60.0200195,262.2199707c-0.2200317-0.3399658-0.6100464-0.4499512-1.2700195-0.4499512
		   c-1.5999756,0-9.8800049,0-9.8800049,0s0,0-1.9899902-1.8400269c-0.6900024-0.6499634-1.539978-0.8399658-2.3499756-0.8200073
		   c0-0.1400146,0.0199585-0.2799683,0.0499878-0.4099731c0.289978,0.0800171,0.6099854,0.0800171,0.9099731,0.0899658
		   c4.1400146,0.0800171,8.2700195,0.1600342,12.4100342,0.2400513c0.6799927,0.0099487,1.4400024,0,1.9499512-0.5100098
		   c0.0300293-0.0300293,0.0500488-0.0599976,0.0700073-0.0900269h0.0100098
		   C59.9299927,258.4299927,61.7000122,260.4199829,60.0200195,262.2199707z"/>
      <path
         d="m 60.821428,273.50181 c -0.03002,0.99 -0.140409,1.72255 -0.401192,1.9581 -0.879187,0.79501 -7.38123,-0.95696 -9.156353,-0.95696 -1.775114,0 -7.361241,-0.0187 -7.762442,-1.44289 -0.0535,-0.18752 -0.08352,-0.42322 -0.100271,-0.69194 0,0 0,0 0,-0.004 -0.06684,-1.08941 0.117038,-2.73106 0.190557,-3.87203 0.04353,-0.72874 0.04679,-1.25882 -0.09029,-1.31399 -0.397783,-0.15819 -1.865311,0.32028 -3.811012,1.19623 -1.942186,0.87598 -4.499598,2.06854 -5.93709,-0.32029 -0.431228,-0.71774 -0.705361,-1.66004 -0.78559,-2.60597 -0.190557,-2.20834 0.671943,-4.45365 3.022058,-4.00816 3.356316,0.63674 5.673048,-1.51277 5.673048,-1.51277 0,0 3.235994,-1.85143 5.221655,0 1.985758,1.84765 1.985758,1.84765 1.985758,1.84765 0,0 8.277236,0 9.875116,0 1.59464,0 1.59464,0.63677 1.674852,3.50031 0.05695,2.02074 0.4714,5.85961 0.401192,8.22633 z"
         id="L_04"/>
      <path id="L04D" d="M60.3900146,280.6799927c-1.2300415-1.2299805-10.3599854-3.2700195-11.3500366-3.4799805
		   c-0.6399536-0.1300049-1.1699829-1.3300171-2.4499512-2.2900391c-0.5100098-0.3799438-1.1500244-0.7299805-1.960022-0.9499512
		   l0.0899658-0.0500488C46.5599976,274.4899902,49.9500122,274.5,51.2600098,274.5
		   c1.6900024,0,7.6199951,1.5700073,8.9799805,1.0599976H60.25C60.25,275.5599976,62.8499756,278.3599854,60.3900146,280.6799927z"/>
      <path
         d="m 60.58073,281.05784 c -0.260783,1.80361 -1.367256,4.92479 -2.032569,9.38942 -0.895918,5.99947 -4.319053,2.52855 -5.141443,2.31513 -0.825718,-0.21716 -11.285856,-7.61162 -11.285856,-7.61162 -0.427935,-0.3019 -0.615173,-0.64409 -0.692047,-1.00482 -0.06013,-0.29815 -0.04017,-0.60361 -0.01669,-0.91646 v -0.0111 c 0.06357,-0.83553 0.183891,-1.70782 -0.899256,-2.43669 -1.995743,-1.35449 -5.311958,4.23284 -7.508297,-1.82551 -0.384434,-1.05273 -0.595042,-2.00605 -0.665242,-2.85256 -0.0035,-0.0403 -0.0071,-0.0772 -0.0099,-0.11406 0.257411,-3.07703 2.580783,-4.3027 4.275667,-3.71745 2.310022,0.79134 2.306606,1.16676 6.48533,1.4318 1.601297,0.10305 2.697784,0.60728 3.500097,1.20722 1.283716,0.96063 1.808603,2.15686 2.447084,2.29308 0.996207,0.20965 10.125897,2.24518 11.349408,3.47821 0.04679,0.0442 0.08026,0.0885 0.103573,0.13241 0.03682,0.0735 0.07028,0.15449 0.09024,0.24302 z"
         id="L_05"/>
      <path id="L05D" d="M55.5300293,294.9000244c-0.0700073-0.0700073-0.1900024-0.0900269-0.3900146-0.0400391
         c-0.9000244,0.2200317-1.7900391,0.2200317-2.6300049-0.0299683c-0.5499878-0.1600342-1.0800171-0.4200439-1.5900269-0.7900391
         c-1.25-0.9299927-2.2099609-2.25-3.3499756-3.3299561c-1.2999878-1.2400513-2.7700195-2.2800293-4.4000244-2.9100342
         c-1.0999756-0.4299927-2.25-0.6599731-3.4099731-0.7199707c0.0899658-0.5800171,0.4400024-1.9200439,1.8399658-2.4800415
         c0.1100464,0.1900024,0.2700195,0.3800049,0.5200195,0.5500488c0,0,10.460022,7.3999634,11.289978,7.6099854
         c0.4800415,0.1300049,1.8600464,1.3800049,3.1000366,1.1699829
         C56.5100098,293.9299927,56.710022,294.9799805,55.5300293,294.9000244z"/>
      <path
         d="m 55.317146,295.88744 c -0.307586,0.45644 -0.778898,0.79503 -1.236913,1.01956 -0.08697,0.0404 -0.732096,0.19877 -0.748844,0.24676 -0.471312,1.28088 -2.149484,5.54308 -4.058367,7.10003 -0.611747,0.8576 -1.490934,1.5275 -2.259855,1.84406 -1.6815,0.68458 -3.530161,0.56681 -5.315269,0.72141 -5.659664,0.49684 -10.998427,3.96402 -14.301302,9.04703 -0.942703,1.45016 -1.6815,3.04385 -2.269867,4.70023 -0.294184,0.82449 -0.551586,1.66735 -0.782253,2.51754 -0.100289,0.37167 -0.207262,0.74719 -0.297521,1.12257 -0.07355,0.31653 -0.06684,0.73253 -0.274116,0.98647 -0.140408,0.17285 -0.361046,0.23552 -0.571645,0.25015 -0.397819,0.0225 -0.798975,-0.0141 -1.196794,-0.03 -0.437921,-0.0141 -0.879188,0.007 -1.317126,0.0697 -0.250709,0.0374 -0.508129,0.0848 -0.748826,0.007 -0.722083,-0.23178 -0.30756,-1.26605 -0.351008,-1.84399 -0.03337,-0.4232 -0.163822,-0.83185 -0.163822,-1.26608 -0.31759,-1.42075 -1.136616,-2.73843 -1.223529,-4.16286 -0.0099,-0.18415 -0.0099,-0.36792 0.0035,-0.55211 0.163814,-1.95811 1.841977,-3.30159 2.574099,-5.09407 0.842423,-2.05008 0.411186,-4.63392 1.6113,-6.4522 1.233568,-1.87343 3.86449,-2.38875 4.663457,-4.52721 0.732104,-1.96913 -0.501446,-4.31374 0.180483,-6.30125 0.448012,-1.29931 1.648108,-2.14217 2.885022,-2.41823 -0.147083,0.0337 -0.725447,-0.76553 -0.775578,-0.88702 -0.09696,-0.2209 -0.08688,-0.52266 0.08697,-0.67726 0.100262,-0.0884 0.230632,-0.11775 0.357726,-0.13982 0.875859,-0.15449 1.611283,-0.14372 2.430344,-0.57053 0.745419,-0.39014 1.427383,-0.92382 2.116101,-1.42071 1.444122,-1.04164 2.998608,-2.11641 4.790471,-2.11641 1.373914,0 2.744587,0.23179 4.044955,0.73613 1.62804,0.63311 3.098985,1.67839 4.402691,2.91512 1.136633,1.08211 2.096023,2.3961 3.349677,3.32362 0.504809,0.37545 1.036353,0.63674 1.587885,0.79873 0.845787,0.24303 1.735057,0.24678 2.630985,0.0263 0.728784,-0.1804 0.464663,0.60359 0.177145,1.0269 z"
         id="S_0"/>
      <path
         d="m 23.762801,333.81326 c 0.08026,0.51531 0.180527,1.04896 0.04017,1.54588 -0.140417,0.50053 -0.608427,0.94217 -1.096505,0.83909 -0.230668,-0.0476 -0.424554,-0.2059 -0.601726,-0.36793 -1.106526,-1.00848 -1.938937,-2.32978 -2.416977,-3.77637 -0.511484,-1.56056 -0.494753,-3.26463 -0.320927,-4.88417 0.120331,-1.11891 1.190102,-0.80605 2.015812,-0.82817 0.541564,-0.0141 2.122793,-0.38284 2.360144,0.35328 0.08026,0.24641 0.02993,0.51896 -0.01669,0.77657 -0.367729,2.09432 -0.29082,4.24017 0.03682,6.34188 z"
         id="coccyx"/>
    </g>
  );
}

const MemoFullSpine = React.memo(FullSpine);
export default MemoFullSpine;
