import React from 'react'
import { Entity } from 'types/analysis';
import placement from 'utils/placement';

import { motion } from 'framer-motion';

export type GraphicsLine = {
  key: string | number;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  radius: number;
  filled: boolean;
  items: Entity[];
}

type Props = {
  lines: GraphicsLine[],
  curved: boolean,
  highlight: Entity[],
  modal: boolean
}
type State = {
  lineBreakpoints: number[]
  highlightColors: {[key: string]: string}
  lineEndXOffset: number;
  lineEndYOffset: number;
}


class GraphicsLines extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    this.state = {
      lineBreakpoints: [],
      highlightColors: {
        "highlight-no-knowledge-data" : "#82ed91",
        "highlight-knowledge-data"    : "#019875",
        "no-highlight"                : "#4D566C"
      },
      lineEndXOffset: isMobile ? 45 : 0, //in case of mobile 40
      lineEndYOffset: isMobile ? 40 : 0,
    }
    new Array(128).fill('#ffffff').map(color => "#" + ((1 << 24) * Math.random() | 0).toString(16))
  }

  render() {
    let { lines, curved, highlight } = this.props;
    const {lineEndXOffset, lineEndYOffset} = this.state;
    lines = lines.sort((a, b) => a.y1 - b.y1);
    const mappedLines = lines.map(line => [line.x1, line.y1, line.x2, line.y2])
    setTimeout(() => {
      this.setState({lineBreakpoints: placement(mappedLines, [230, 235])});
      // this.setState({lineBreakpoints: placement(mappedLines, [230, 235])});
    }, 500);

    const includes = (e: Entity) => {
      for (const h in highlight) {
        if (JSON.stringify(highlight[h]) === JSON.stringify(e)) return true;
      }
      return false;
    }

    const {lineBreakpoints} = this.state;

    const highlightGenerator = (highlight: Array<Entity>, line: GraphicsLine) : string => {
      if (highlight.length > 0 && line.items.find(entity => includes(entity)))
        return this.state.highlightColors[`highlight-${highlight.some(e=>e.video.some(e=>e>0)) ? 'no-knowledge-data' : 'knowledge-data'}`];
      return this.state.highlightColors["no-highlight"];
    }

    return (
      <div className="IGraphicsLines">
        {lines.map((line, index) =>
          <motion.svg
            className={`IGraphicsLines__svg${highlight.length>0 ? (line.items.find(entity => includes(entity))
              ? "__highlight"
              : "__background") : ""}`}
            key={line.key + line.x1.toString() + line.y1.toString() + curved.toString()}
            transition={{ duration: 1, delay: 0.5 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.75 }}
          >
            <circle
              className="IGraphicsLines__circle-inside"
              cx={line.x1}
              cy={line.y1}
              // cy={line.y1-1.5}
              r={1}
              fill={highlightGenerator(highlight, line)}
              style={{ stroke: highlightGenerator(highlight, line) }}
            />
            <path
              className="IGraphicsLines__line"
              key={index}
              d={`
                M${line.x1} ${line.y1}
                C ${lineBreakpoints[index]} ${line.y1} ${lineBreakpoints[index]} ${line.y2+lineEndYOffset} ${line.x2-lineEndXOffset} ${line.y2+lineEndYOffset}`
              }
              style={{ stroke: highlightGenerator(highlight, line) }}
            />
            <circle
              className="IGraphicsLines__circle-inside"
              cx={line.x2-lineEndXOffset}
              cy={line.y2+lineEndYOffset}
              r={1}
              fill={highlightGenerator(highlight, line)}
              style={{ stroke: highlightGenerator(highlight, line) }}
            />
            {line.items.find(entity => includes(entity)) &&
              <circle
                className="IGraphicsLines__circle"
                cx={line.x2-lineEndXOffset}
                cy={line.y2+lineEndYOffset}
                r={.5}
                fill={line.filled ? highlightGenerator(highlight, line) : 'transparent'}
                style={{ stroke: highlightGenerator(highlight, line) , strokeWidth: 3 }}
              />
            }
          </motion.svg>
        )
        }
      </div>
    )
  }
}

export default GraphicsLines;
