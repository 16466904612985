import "./Graphics.scss";

import React from "react";
import { Area, Entity } from "types/analysis";

import sources from "constants/sources";
import areaMap from "constants/areaMap.json";

import GraphicsContent from "./GraphicsContent";

type Props = {
  className?: string;
  src?: string;
  areas?: { [key: string]: Area };
  updateHighlight?: (analysis: Entity[]) => any;
  onFullScreen?: () => any;
  analysis: Entity[];
  analysisLoading: boolean;
  fileId: string;
  mobileHighlight: Function;
};

type State = {
  fullScreen: boolean;
  arrangement: boolean;
  linesVisible: boolean;
  linesCurved: boolean;
};
class Graphics extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fullScreen: false,
      arrangement: false,
      linesVisible: true,
      linesCurved: false,
    };
  }

  render() {
    const {
      className = "",
      analysis = [],
      src = sources.spineSVG,
      areas = areaMap,
      analysisLoading = false,
    } = this.props;
    const { linesVisible, arrangement } =
      this.state;

    return (
      <div className={`Graphics ${className}`} id="print">
        <GraphicsContent
          src={src}
          areas={areas}
          analysis={analysis}
          arrangement={arrangement}
          linesCurved={true}
          linesVisible={linesVisible}
          updateHighlight={this.props.updateHighlight}
          analysisLoading={analysisLoading}
          fileId={this.props.fileId}
          mobileHighlight={this.props.mobileHighlight}
        />
      </div>
    );
  }
}

export default Graphics;
