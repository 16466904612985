import React from "react";

import { getOffsetAndPositions } from "utils/offset";
import { Area, Entity } from "types/analysis";
import { GraphicsLine, GraphicsCausationLine } from ".";

import { motion } from "framer-motion";
import GraphicsElement from "./GraphicsElement";

import { Button } from "antd";

type GroupGraphics = {
  negative: boolean;
  positive: boolean;
  neutral: boolean;
  x: number | undefined;
  y: number | undefined;
  size: number | undefined;
  filled: boolean;
};

type Group = {
  index: number;
  key: string | number;
  items: Entity[];
  graphics: GroupGraphics;
};

type Props = {
  analysis: Entity[];
  areas: { [key: string]: Area };
  highlight: Entity[];
  arrangement: boolean;
  modal: number;
  onHover: (entity?: Entity) => any;
  setLines: (lines: GraphicsLine[]) => any;
  setCausationLines: (lines: GraphicsCausationLine[]) => any;
  resetLines: boolean;
  segment: string;
  fileId: string;
  lineOffset: number;
  imgOffset: number;
  mobileHighlight: Function;
};
type State = {};

const corrigation: { [key: string]: number } = {
  cervicalis_lordosis: 120,
  thoracalis_kyphosis: 0,
  lumbalis_lordisis: 150,
  sacro_coccygealis_kyphosis: 300,
};

class GraphicsList extends React.Component<Props, State> {
  private causationRefs: {
    [key: string]: {
      ref: HTMLDivElement;
      annId: string;
      source: string;
    };
  } = {};
  private lineRefs: {
    [key: string]: {
      ref: HTMLDivElement;
      group: Group;
    };
  } = {};

  constructor(props: Props) {
    super(props);
    this.causationRefs = {};
    this.lineRefs = {};
    this.state = {};
  }

  componentDidMount = async () => {
    setTimeout(this.setLines, 10);
  };
  componentDidUpdate = (prevProps: Props, prevState: State) => {
    (this.props.analysis !== prevProps.analysis ||
      this.props.arrangement !== prevProps.arrangement) &&
      setTimeout(this.setLines, 0);
  };

  handleHover = (entity?: Entity) => {
    this.props.onHover(entity);
  };

  handleCausationRef = (ref: HTMLDivElement | null, element: Entity) => {
    const { annId, causations } = element;
    const sourceEntity = causations[0];

    if (element.entities.length > 0) {
      element.entities.forEach((e) => {
        this.handleCausationRef(ref, e);
      });
    }

    if (!sourceEntity) return;
    const x = sourceEntity!.annId;
    const sourceRef = document.getElementById(
      `${sourceEntity.annId}-${this.props.segment}-${this.props.fileId}`
    );

    this.causationRefs = {
      ...this.causationRefs,
      [annId]: { ref, annId, source: sourceEntity.annId },
      [sourceEntity.annId]: { ref: sourceRef, annId: x, source: "" },
    };
  };

  setCausationLines = () => {
    const refs = this.causationRefs;

    const lines = [];
    for (const key of Object.keys(refs)) {
      const { ref: destRef, source } = refs[key];

      if (!destRef || !source) continue;
      const sourceElement = refs[source];

      const { ref: sourceRef } = sourceElement;

      if (!sourceRef) continue;
      const offset = getOffsetAndPositions(destRef, "Graphics__content");
      const sourceOffset = getOffsetAndPositions(
        sourceRef,
        "Graphics__content"
      );
      const x1 = sourceOffset.right - this.props.modal * 16;
      const y1 = sourceOffset.vCenter - this.props.modal * 125;
      const x2 = offset.right - this.props.modal * 20;
      const y2 = offset.vCenter - this.props.modal * 127;
      const line = { x1, y1, x2, y2 };
      lines.push(line);
    }
    this.props.setCausationLines(lines);
  };

  handleLineRef = (ref: HTMLDivElement | null, group: Group) => {
    this.lineRefs = { ...this.lineRefs, [group.index]: { ref, group } };
  };
  setLines = () => {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    const inIframe = () =>
      window.self !== window.top ? (isMobile ? 171 : 143) : 74;
    const inIframeY = () =>
      window.self !== window.top ? (isMobile ? 40 : 0) : 74;
    const refs = this.lineRefs;
    const lines = Object.keys(refs)
      .map((key) => {
        const { ref, group } = refs[key];
        const { graphics, items } = group;
        if (!ref || (!graphics.x && !graphics.y)) return undefined;
        const offset = getOffsetAndPositions(ref, "Graphics__content");
        const x1 = offset.right - this.props.modal * 20;
        const y1 = offset.vCenter - this.props.modal * 127;
        const x2 = (graphics.x as unknown as number) +
          this.props.lineOffset +
          ((document.getElementsByClassName("GraphicsList")[0] as any)
            .offsetWidth -
            inIframe());
        const y2 = (graphics.y as unknown as number) - inIframeY();
        const radius = graphics.size;
        const filled = graphics.filled;
        const line = {
          key,
          items,
          x1,
          x2,
          y1,
          y2,
          radius,
          filled,
        };
        return line;
      })
      .filter((element) => element) as GraphicsLine[];

    this.props.setLines(lines);
    this.setCausationLines();
  };

  createGroups = (analysis: Entity[]): Group[] => {
    const groups: Group[] = [];
    const groupObj: { [key: string]: Group } = {};
    analysis.forEach((entity, index) => {
      const { visualId, visuals } = entity;
      if (entity.type === "NEGATION") return;
      const diagnosisAreas = visuals
        .map(
          (
            element //@ts-ignore
          ) => this.props.areas[element]
        )
        .filter((element) => element) as Area[];
      const x =
        diagnosisAreas.reduce((sum, area) => (sum += area.center[0]), 0) /
        (diagnosisAreas.length || 1);
      const y =
        diagnosisAreas.reduce((sum, area) => (sum += area.center[1]), 0) /
        (diagnosisAreas.length || 1);
      const size =
        diagnosisAreas.length === 1 ? 2 : 5 + diagnosisAreas.length * 16;
      const filled = diagnosisAreas.length === 1;

      const key = visualId || entity.text || entity.startIndex;
      const group: Group = (key && groupObj[key]) || {
        index,
        key,
        items: [],
        graphics: {
          negative: false,
          positive: false,
          neutral: false,
          x: undefined,
          y: undefined,
          size: 10,
          filled: false,
        },
      };

      group.items.push(entity);
      group.graphics.negative = group.graphics.negative || entity.negative;
      group.graphics.positive = group.graphics.positive || entity.positive;
      group.graphics.neutral = group.graphics.neutral || entity.neutral;
      group.graphics.x = x;
      group.graphics.y = y;
      group.graphics.size = size;
      group.graphics.filled = filled;

      if (!groupObj[key]) {
        if (key) groupObj[key] = group;
        groups.push(group);
      }
    });
    groups.forEach(
      (group) =>
        (group.items = group.items.sort((a, b) => a.startIndex - b.startIndex))
    );
    // Rendezés szövegpozicio szerint fán belül is (továbbiakbaan lásd a komplex objektumösszerakást backenden)
    return groups;
  };

  render() {
    const { analysis, highlight, arrangement } = this.props;
    const groups = this.createGroups(analysis);
    const sortedGroups = arrangement
      ? groups.sort((a, b) => {
          if (!a.graphics.y) return 1;
          if (!b.graphics.y) return -1;
          return Number(a.graphics.y) - Number(b.graphics.y);
        })
      : groups.sort((a, b) => a.items[0].startIndex - b.items[0].startIndex);

    return (
      <div className="GraphicsList">
        {sortedGroups.map((group, index) => (
          <motion.div
            key={group.key}
            transition={{ duration: 0.5 }}
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            layout
          >
            <div
              className={`
                GraphicsListElement 
                GraphicsListElement--${group.items.length > 1 && "bordered"}`}
              ref={(ref) => this.handleLineRef(ref, group)}
              key={index}
            >
              {group.items.map((entity, index) => {
                let x = false;
                highlight.forEach((e) => {
                  if (JSON.stringify(entity) === JSON.stringify(e)) x = true;
                });
                return (
                  <div className="GraphicsElementContainer">
                    <GraphicsElement
                      entity={entity}
                      highlight={x}
                      onHover={this.handleHover}
                      handleRef={this.handleCausationRef}
                      segment={this.props.segment}
                      fileId={this.props.fileId}
                      jollyJoker={group.items.length > 0}
                      mobileHighlight={this.props.mobileHighlight}
                    />
                  </div>
                );
              })}
            </div>
          </motion.div>
        ))}
      </div>
    );
  }
}
export default GraphicsList;
