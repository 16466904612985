import React from "react";

import { Area, Entity } from "types/analysis";
import { GraphicsLine, GraphicsCausationLine } from ".";

import GraphicsImage from "./GraphicsImage";
import GraphicsList from "./GraphicsList";
import GraphicsLines from "./GraphicsLines";
import sources from "constants/sources";
import areaMap from "constants/areaMap.json";
import { DATA_PROVIDER } from "components/ImageMapper/DataContext";

type Props = {
  className?: string,
  id?: string | number,
  src?: string,
  areas?: { [key: string]: Area },
  updateHighlight?: (analysis: Entity[]) => any
  analysis: Entity[],
  linesVisible: boolean,
  linesCurved: boolean,
  arrangement: boolean,
  fileId: string,
  analysisLoading: boolean,
  mobileHighlight: Function
}

type State = {
  loaded: boolean;
  highlight: Entity[];
  lines: GraphicsLine[];
  causationLines: GraphicsCausationLine[];
  reset: boolean;
  offset: number;
};



class GraphicsContent extends React.Component<Props, State> {
  private ref: any = React.createRef();
  constructor(props: Props) {
    super(props);
    this.state = {
      loaded: false,
      highlight: [],
      lines: [],
      causationLines: [],
      reset: false,
      offset: 0
    };
  }

  handleListHover = (entity?: Entity) => {
    const highlight = entity ? [entity] : [];
    this.setState({ highlight });
    this.props.updateHighlight && this.props.updateHighlight(highlight);
  };

  handle = (ref: any) => {
    setTimeout(()=>this.setState({offset:ref?(ref as unknown as any).offsetTop : 0}),100)
  }

  handleImageHover = (subject?: string) => {
    const { analysis } = this.props;
    const highlight = subject
      ? analysis.filter((entity) => entity.visuals.includes(subject))
      : [];
    this.setState({ highlight });
    this.props.updateHighlight && this.props.updateHighlight(highlight);
  };

  render() {
    const {
      src = sources.spineSVG,
      areas = areaMap,
      analysis,
      linesVisible,
      linesCurved,
      arrangement,
      analysisLoading = false,
      fileId
    } = this.props;
    const { highlight, causationLines, lines, offset } = this.state;

    return (
      <div key={this.props.id} className={`Graphics__content`}>
      {analysisLoading && (
        <GraphicsList
          areas={areas}
          analysis={analysis}
          highlight={highlight}
          arrangement={arrangement}
          onHover={this.handleListHover}
          resetLines={!this.state.reset}
          setLines={(lines) => this.setState({ lines })}
          setCausationLines={(causationLines) =>
            this.setState({ causationLines })
          }
          modal={0}
          segment="full"
          fileId={fileId}
          lineOffset={offset}
          imgOffset={this.state.offset}
          mobileHighlight={this.props.mobileHighlight}
        />
      )}
        <div
          className={`Graphics__image-wrapper ${
            src !== sources.spineSVG ? "Graphics__image-wrapper--dark" : ""
          }`}
          // style={{margin:(src !== sources.spineSVG?"auto":"unset")}}
          ref={this.handle}
        >
          <DATA_PROVIDER value={{
            areas: areas,
            analysis: analysis,
            highlight: highlight,
            arrangement: arrangement,
            lines: lines,
            curved: linesCurved,
            linesVisible: linesVisible,
            causationLines: causationLines,
            onHover: this.handleListHover,
            onImageHover: this.handleImageHover,
            resetLines: (resetL: boolean) => {
              this.setState({reset: resetL});
            },
            setLines:(lines: GraphicsLine[]) => this.setState({lines}),
            setCausationLines:(causationLines: GraphicsCausationLine[]) => this.setState({ causationLines }),
            analysisLoading: {analysisLoading}
          }}>
            <GraphicsImage
              src={src}
              areas={areas}
              analysis={analysis}
              highlight={highlight}
              onHover={this.handleImageHover}
              onLoad={() => this.setState({ loaded: true })}
            />
          </DATA_PROVIDER>
        </div>
        {(analysisLoading && linesVisible) && (
          <GraphicsLines
            lines={lines}
            highlight={highlight}
            curved={linesCurved}
            modal={false}
          />)
        }
      </div>
    );
  }
}

export default GraphicsContent;
