import React from 'react';

import { Area, Entity } from 'types/analysis';

import areaMap from 'constants/areaMap.json';
import sources from 'constants/sources';

import ImageMapper from 'components/ImageMapper';
import { BEK_AREA } from 'components/ImageMapper/ImageMapperSVG';


type Props = {
  className?: string,
  analysis: Entity[],
  highlight: Entity[],
  onHover?: (key?: string) => any,
  onLoad?: () => any,
  src?: string,
  areas?: { [key: string]: Area | BEK_AREA }
}
type State = {}

class GraphicsImage extends React.Component<Props, State> {
  handleHover = (key?: string) => {
    this.props.onHover && this.props.onHover(key)
  }

  render() {
    const {
      className = "",
      analysis = [],
      highlight = [],
      src = sources.spineSVG,
      areas = areaMap,
    } = this.props;    
    // Sets
    const keySet       : Set<string>    = new Set<string>();
    const highlightSet : Set<string>    = new Set<string>()
    const negativeSet  : Set<string>    = new Set<string>();
    const positiveSet  : Set<string>    = new Set<string>();
    const neutralSet   : Set<string>    = new Set<string>();
    const knowledgeSet : Set<string>    = new Set<string>();

    // Fill sets
    highlight.forEach(entity =>
      entity.visuals.forEach(visual => highlightSet.add(visual))
    );
    analysis.forEach(entity => {
      entity.visuals.forEach(visual => keySet.add(visual))
      if (entity.negative) entity.visuals.forEach(visual => negativeSet.add(visual))
      if (entity.positive) entity.visuals.forEach(visual => positiveSet.add(visual))
      if (entity.neutral) entity.visuals.forEach(visual => neutralSet.add(visual))
      if (entity.video.some(e => e > 0)) entity.visuals.forEach(visual => highlightSet.has(visual) && knowledgeSet.add(visual))
    })

    // Create areas   
    const map: any = [];
    keySet.forEach(key => {
      const area_ = areas[key as keyof Area];
      if (!area_) return;
      
      const negative = negativeSet.has(key);
      const positive = positiveSet.has(key);
      const neutral = neutralSet.has(key);
      const highlighted = highlightSet.has(key);
      const className = `${(negative && "negative") || (positive && "positive") || (neutral && "neutral")}${highlighted ? "--highlight" : ""}${highlighted ? (!knowledgeSet.has(key) ? "--knowledge-data" : "--no-knowledge-data") : ''}`;
      let area: any;
      
      area = {
        className,
        key,
        path: (area_ as unknown as Area).path,
        shape: area_.shape,
      }
      map.push(area)
    });
    
    return (
      <div className={`GraphicsImage ${className}`} >
        <ImageMapper
          src={src}
          areas={map}
          onLoad={this.props.onLoad}
          onHover={(key?: string) => this.handleHover(key)}
        />
      </ div>
    );
  }
}

export default React.memo(GraphicsImage);