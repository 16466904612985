const print = () => { }
// console.log;

const placement = (lines = [], drawing_field = []) => {
  //count overlaps by line
  var overlaps_by_line = [];
  for (var a_line of lines) {
    var current_overlaps = 0;
    for (var another_line of lines) {
      if (is_overlap(a_line, another_line)) {
        current_overlaps++;
      }
    }
    overlaps_by_line.push(current_overlaps)
  }

  var overlaps_by_line_max = [];
  for (var a_line of lines) {
    var max_overlapper = 0;
    for (var i = 0; i < lines.length; i++) {
      var another_line = lines[i]
      if (is_overlap(a_line, another_line) && max_overlapper < overlaps_by_line[i]) {
        max_overlapper = overlaps_by_line[i]
      }
    }
    overlaps_by_line_max.push(max_overlapper);
  }

  var result_linebreaks = [];

  //determine partitions if the lines can be partitioned
  var to_sort = [];
  var need_slots = 0;
  var already_sorted = 0;
  for (var i = 0; i < overlaps_by_line_max.length; i++) {
    to_sort.push(i);
    if (need_slots < overlaps_by_line_max[i]) {
      need_slots = overlaps_by_line_max[i];
    }
    var possible_new_partition = false;
    if (i - overlaps_by_line_max[i] + 1 >= 0 && i - overlaps_by_line_max[i] + 1 >= already_sorted) {
      possible_new_partition = true;
      for (var j = i - overlaps_by_line_max[i] + 1; j < i; j++) {
        if (overlaps_by_line_max[j] !== overlaps_by_line_max[i]) {
          possible_new_partition = false
        }
      }
    }

    if (possible_new_partition) {
      //  making a new partition
      //  temporary slots
      already_sorted += to_sort.length;
      var horizontal_slots = [];
      for (var i2 = 0; i2 < need_slots; i2++) {
        horizontal_slots.push(i2);
      }

      var slots_by_line = [];
      var next_empty_slot = 0;
      for (var i2 = 0; i2 < to_sort.length; i2++) {
        var current_line = lines[to_sort[i2]];
        var new_slot_needed = true;
        for (var a_slot of horizontal_slots) {
          var taken = false;
          for (var k2 = 0; k2 < slots_by_line.length; k2++) {
            if (slots_by_line[k2] === a_slot && is_overlap(current_line, lines[to_sort[k2]])) {
              taken = true;
            }
          }
          if (!taken) {
            slots_by_line.push(a_slot);
            new_slot_needed = false;
            break;
          }
        }
        if (new_slot_needed) {
          slots_by_line.push(horizontal_slots[next_empty_slot])
          next_empty_slot++;
        }
      }
      print(slots_by_line)
      //determine possible line break locations (slots)
      var drawing_field_size = drawing_field[1] - drawing_field[0];
      var slot_gap = drawing_field_size / (slots_by_line.reduce((a, b) => Math.max(a, b)) + 1)
      horizontal_slots = [];
      var k = drawing_field[0] + slot_gap / 2;
      print(k)
      while (Math.floor(k) <= drawing_field[1]) {
        horizontal_slots.push(Math.floor(k));
        k += slot_gap;
      }
      print(horizontal_slots)
      for (var a_result of slots_by_line) {
        result_linebreaks.push(horizontal_slots[a_result]);
      }
      to_sort = [];
      need_slots = 0;
    }
  }

  if (to_sort.length > 0) {
    //  if any remained unpartitioned, draw their lines also
    var horizontal_slots = [];
    for (var i2 = 0; i2 < need_slots; i2++) {
      horizontal_slots.push(i2);
    }

    var slots_by_line = [];
    var next_empty_slot = 0;
    for (var i2 = 0; i2 < to_sort.length; i2++) {
      var current_line = lines[to_sort[i2]];
      var new_slot_needed = true;
      for (var a_slot of horizontal_slots) {
        var taken = false;
        for (var k2 = 0; k2 < slots_by_line.length; k2++) {
          if (slots_by_line[k2] === a_slot && is_overlap(current_line, lines[to_sort[k2]])) {
            taken = true;
          }
        }
        if (!taken) {
          slots_by_line.push(a_slot);
          new_slot_needed = false;
          break;
        }
      }
      if (new_slot_needed) {
        slots_by_line.push(horizontal_slots[next_empty_slot])
        next_empty_slot++;
      }
    }
    print(slots_by_line)
    //determine possible line break locations (slots)
    var drawing_field_size = drawing_field[1] - drawing_field[0];
    var slot_gap = drawing_field_size / (slots_by_line.reduce((a, b) => Math.max(a, b)) + 1)
    horizontal_slots = [];
    var k = drawing_field[0] + slot_gap / 2;
    print(k)
    while (Math.floor(k) <= drawing_field[1]) {
      horizontal_slots.push(Math.floor(k));
      k += slot_gap;
    }
    print(horizontal_slots)
    for (var a_result of slots_by_line) {
      result_linebreaks.push(horizontal_slots[a_result]);
    }
  }

  // let resStr = "["
  // lines.forEach(line => resStr += `[${line[0]},${line[1]},${line[2]},${line[3]}],`)
  // resStr += "]"
  // console.log(resStr)
  // console.log({ result_linebreaks })

  return result_linebreaks
}

const is_overlap = (first_line, second_line) => {

  var min1 = Math.min(first_line[1], first_line[3])
  var min2 = Math.min(second_line[1], second_line[3])
  var max1 = Math.max(first_line[1], first_line[3])
  var max2 = Math.max(second_line[1], second_line[3])
  // print(min1, max1, min2, max2)
  if ((min1 <= min2 && max1 >= min2) || (min2 <= min1 && max2 >= min1)) {
    return true
  }
  return false
}


// const ex_coords = [[0, 1, 400, 0], [0, 2, 400, 1], [0, 3, 400, 1], [0, 3, 400, 10], [0, 5, 400, 3], [0, 5, 400, 16], [0, 7, 400, 5], [0, 8, 400, 6], [0, 8, 400, 7], [0, 8, 400, 11], [0, 8, 400, 14], [0, 10, 400, 8], [0, 10, 400, 12], [0, 10, 400, 17]]
// const ex_coords = [[0, 1, 400, 3], [0, 2, 400, 0], [0, 3, 400, 3], [0, 5, 400, 5], [0, 5, 400, 12], [0, 7, 400, 7], [0, 8, 400, 6], [0, 10, 400, 17]]

// const drawing_field = [150, 300]

// const res = placement(ex_coords, drawing_field)


export default placement;