import React from "react";
import {Entity} from "types/analysis";

import Chip from "@mui/material/Chip";

type Props = {
  entity: Entity;
  highlight: boolean;
  onClick: Function;
  onContextMenu: Function;
  isMobile: boolean;
  isIphone: boolean;
};
type State = {
  hover: boolean;
  highlightColors: {[key: string]: string};
};

// class GraphicsTag extends React.Component<Props, State> {
//   constructor(props : Props) {
//     super(props);
//     this.state = {
//       hover: false,
//       highlightColors: {
//         "highlight-no-knowledge-data" : "#82ed91",
//         "highlight-knowledge-data"    : "#019875",
//         "no-highlight"                : ""
//       }
//     };
//   }

//   render() {
//     const {entity, highlight} = this.props;
//     const {iLeletText}        = entity;
//     const {highlightColors}   = this.state;

//     return (<div className="IGraphicsTag">
//       <Chip
//         label={iLeletText}
//         className={`IGraphicsTag__tag IGraphicsTag__title`}
//         onMouseOver={() => this.setState({hover: true})}
//         onMouseLeave={() => this.setState({hover: false})}
//         style={{
//           backgroundColor: highlightColors[`${highlight ? 'highlight' : 'no-highlight'}${entity.video.some(e=>e>0) ? '-no-knowledge-data' : '-knowledge-data'}`]
//         }}
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           textAlign: "center",
//         }}
//         onClick={event => this.props.onClick(entity)}
//         onContextMenu={event => this.props.onContextMenu(entity, event)}
//         onDoubleClick={event => this.props.onContextMenu(entity, event)}
//       />
//     </div>);
//   }
// }

const GraphicsTag: React.FC<Props> = ({entity, highlight, isIphone, onClick, isMobile, onContextMenu}) => {
  const highlightColors : {[key: string] : string}={
    "highlight-no-knowledge-data" : "#82ed91",
    "highlight-knowledge-data"    : "#019875",
    "no-highlight"                : ""
  };

  const onlongtouch = (event: any) => {
    onContextMenu(entity, event);
  }; 
  var timer: NodeJS.Timeout | null = null;
  var touchduration = 500;

  const touchstart = (e: any) => {
    e.preventDefault();
    timer = setTimeout(onlongtouch, touchduration); 
  }

  const touchend = () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
        onClick(entity);
        return;
      };
  }

  const {iLeletText} = entity;
  return (
    <div className="IGraphicsTag" onTouchStart={touchstart} onTouchEnd={touchend}>
      {
        <Chip
          label={iLeletText}
          className={`IGraphicsTag__tag IGraphicsTag__title`}
          style={{
            backgroundColor: highlightColors[`${highlight ? 'highlight' : 'no-highlight'}${entity.video.some(e=>e>0) ? '-no-knowledge-data' : '-knowledge-data'}`]
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
          onClick={event => (isMobile) ? {} : onClick(entity)}
        />
      }
    </div>
  );
};

export default GraphicsTag;
