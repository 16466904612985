import React, { Component } from "react";
import { Entity } from "types/analysis";

import GraphicsTag from "./GraphicsTag";

/**
 * `GraphicsElement` Props modelje
 */
type Props = {
  entity: Entity;
  highlight: boolean;
  onHover: (entity?: Entity) => any;
  handleRef: (ref: any, element: Entity) => any;
  segment: string;
  fileId: string;
  mobileHighlight: Function;
  jollyJoker: boolean; //Fuck u in particular, ha ezt a változót itt kitörlöm, akkor eltöri a következmény vonalakat az analysis felüleleten.
};
type State = {
  isMobile: boolean;
  selected: boolean;
};

/**
 * Ez a komponens felelős a fa elemeinek megjelenítésért. (Ez renreli a "chipeket", amik tartalmazzás a testrészt és az elváltozásokat)
 *
 * @version x.x.x
 * @author Németh Péter
 * @public
 */
class GraphicsElement extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isMobile:
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ),
      selected: false,
    };
  }
  /**
   * Meghívja a `this.props.onHover` metódusát, amit a `GraphicsList`-ből kap
   *
   * @param entity A lista elem, amin áthaladt a kurzor
   * @public
   */
  handleHover = (entity?: Entity) => {
    this.props.onHover(entity);
  };

  /**
   * Ha tippelnem kellene, akkor ennek a következmény vonalakhoz van valami köze, de nem vennék rá mérget
   *
   * @public
   */
  handleRef = (ref: any, element: Entity) => {
    this.props.handleRef(ref, element);
  };

  /**
   * Rendereli a komponenst, valamint konstruktor híjján itt van a `props` magic is
   *
   * @public
   */
  render() {
    const { entity, highlight, segment } = this.props;

    const handleClick = (entity: Entity) => {
      const { isMobile } = this.state;

      if (!isMobile) {
        window.parent.postMessage(entity, "*");
        // Not Iphone
      } else if (/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.props.mobileHighlight(null);
        if (this.props.highlight) this.handleHover();
        else this.handleHover(entity);
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        this.props.mobileHighlight(null);
      }
    };
    
    const handleContextMenu = (
      entity: Entity,
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      if (/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && event)
          event.preventDefault();

      if (this.state.isMobile) {
        if (this.state.selected && this.props.highlight) {
          this.handleHover();
          this.props.mobileHighlight(null);
        } else {
          this.setState({ selected: true });
          this.handleHover(entity);
          this.props.mobileHighlight(entity);
        }
      }
    };

    return (
      <div
        className={`
          GraphicsElement
          GraphicsElement$`}
        onMouseOver={() => this.handleHover(entity)}
        onMouseOut={() => this.handleHover()}
      >
        <div className="GraphicsElement__subject">
          <GraphicsTag
            isMobile={this.state.isMobile}
            isIphone={/iPhone|iPad|iPod/i.test(navigator.userAgent)}
            entity={entity}
            highlight={highlight}
            onClick={handleClick}
            onContextMenu={handleContextMenu}
          />
        </div>
      </div>
    );
  }
}

export default React.memo(GraphicsElement);