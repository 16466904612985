import graphicsMap from "constants/graphicsMap.json";
import areaMap from "constants/areaMap.json";

export type EntityType = "SUBJECT" | "CONDITION" | "LABEL" | "POSITION" | "NEGATION" | "SEQUENCE" | "RECORDING" | "PLANE" | "NULL";

export type Area = {
  path: string;
  center: number[];
  shape: "poly" | "circle" | string;
};

export type AnalysisResponse = {
  entities: EntityResponse[];
  annotations: {
    entities: any[];
    relations: any[];
  };
  ann: string;
};

export type EntityResponse = {
  type: EntityType;
  hidden: boolean;

  annId: number;
  idList: string[];
  startIndex: number;
  endIndex: number;
  word: string;
  lemma: string;

  text: string;

  context: boolean;
  negated: boolean;

  positive: boolean;
  negative: boolean;
  neutral: boolean;

  dimensions: {
    single: string;
    interval: string;
    area: string;
  };
  idListAll: string[];
  entityIdIndexes: string[];
  causations: EntityResponse[] | Entity[];
  entities: EntityResponse[] | Entity[];

  video: number[];
  severity: number;
  lang_word: string;
  latin_word: string;
  iLeletText: string;
};

export type Analysis = {
  entities: Entity[];
  annotations: {
    entities: any[];
    relations: any[];
  };
  ann: string;
};

export type Entity = EntityResponse & {
  visualId: string;
  visuals: string[];
  causations: Entity[];
  entities: Entity[];
};

export const toEntity = (
  entityResponse: EntityResponse,
  text = "",
  areas = areaMap
): Entity => {
  const visualsRaw = entityResponse.idListAll
    .map((id) => graphicsMap[id as keyof typeof graphicsMap])
    .filter((x) => x)
    .map((id) => (areas[id as keyof typeof areaMap] ? id : id.split("_")[0]))
    .filter((x) => x) as string[];
  
  const visuals = visualsRaw.filter((visual) =>
    visualsRaw.find((element) => element.includes("_"))
      ? visual.includes("_")
      : visual
  );

  const visualId = visuals.join(" ");
  const entities = entityResponse.entities.map((element) => toEntity(element));
  const causations = entityResponse.causations.map((element) =>
    toEntity(element)
  );
  const entity = {
    ...entityResponse,
    visuals,
    visualId,
    causations,
    entities,
  };
  return entity;
};

export const toAnalysis = (
  analysisResponse: AnalysisResponse,
  text = "",
  areas = areaMap
): Analysis => {
  const analysis = {
    ...analysisResponse,
    entities: analysisResponse.entities.map((entityResponse) =>
      toEntity(entityResponse, text, areas)
    ),
  };

  return analysis;
};

export const prepare = (analysisResponse: AnalysisResponse): AnalysisResponse => {
  const processEntity = (entity: EntityResponse) => {
    if (entity.type === "CONDITION") {
      return entity
    }
    return;
  }


  let result: AnalysisResponse = {entities:[],ann: analysisResponse.ann, annotations: analysisResponse.annotations}
  for (let i in analysisResponse.entities) {
    let x = analysisResponse.entities[i];
    if (x.type === "CONDITION") result.entities.push(x);
    else if (x.type === "SUBJECT") {
      let idList: string[] = x.idListAll;
      let temp: EntityResponse[] = [];
      for (let j in x.entities) {
        let y: EntityResponse | undefined = processEntity(x.entities[j]);
        if (y === undefined) continue;
        y.idListAll = idList;
        temp.push(y)
      }
      result.entities = result.entities.concat(temp);
    }
  }
  return result;
}