import "./ImageMapper.scss";
import React from "react";
import DATA_CONTEXT from "./DataContext";
import { Entity } from "types/analysis";
import sources from "constants/sources";
import Body from "./spine_graphics/Body";

import FullSpine from "./spine_graphics/FullSpine";


export type Area = {
  key: string;
  path: string;
  shape: "poly" | "circle";
  className: string;
};
export type BEK_AREA = {
  key: string;
  coords: number[];
  shape: "poly" | "circle";
  className: string;
};
type Props = {
  className?: string;
  src: string;
  onLoad?: () => any;
  onHover: (key?: string) => any;
  areas: Area[] | BEK_AREA[];
};
type State = {
  fullScreen: boolean;
  segment: string;
  modalAnalysis: Entity[];
  loaded: boolean;
};

class ILeletImageMapper extends React.Component<Props, State> {
  private refImg = React.createRef<HTMLImageElement>();
  private refSvg = React.createRef<SVGSVGElement>();
  private scale: number = 1;
  static contextType = DATA_CONTEXT;
  private data: { [key: string]: any } = {
    areas: null,
    analysis: null,
    highlight: null,
    arrangement: null,
    onHover: null,
    setLines: null,
    setCausationLines: null,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      fullScreen: false,
      segment: "full",
      modalAnalysis: [],
      loaded: false,
    };
  }

  componentDidMount() {
    this.data = this.context;
  }

  componentDidUpdate() {
    this.data = this.context;
  }

  onLoad = () => {
    const nodeImg = this.refImg.current!;
    this.scale = nodeImg.height / nodeImg.naturalHeight || 1;
    this.props.onLoad && this.props.onLoad();
    this.forceUpdate();
    this.setState({
      loaded: true,
    });
    if (this.data !== undefined) this.data.resetLines(true);
  };

  render() {
    // #region svgContent function, for svg error erradication
    const svgContent = () => {
      return (
        <g>
          {src === sources.spineSVG && <Body />}
          {src === sources.spineSVG && <FullSpine />}
          {areas.map((area: Area | BEK_AREA) =>
              (this.data === undefined ? true : this.data.analysisLoading) && (
                <path
                  className={`${area.className}${area.key.includes("00") ? "-nofill":""}`}
                  key={area.key}
                  d={(area as unknown as Area).path}
                  onMouseOver={() => this.props.onHover(area.key)}
                  onMouseOut={() => this.props.onHover()}
                  z-index="2"
                />
              )
          )}
        </g>
      );
    };
    // #endregion
    const { className, src } = this.props;
    const scale = this.scale;

    let areas: any;
    if (src === sources.spineSVG) {
      areas =
        scale === 1
          ? this.props.areas
          : this.props.areas.map((area) => ({
              ...area,
            }));
    } else {
      areas =
        scale === 1
          ? this.props.areas
          : this.props.areas.map((area) => ({
              ...area,
              coords: (area as unknown as BEK_AREA).coords.map(
                (coord) => coord * this.scale
              ),
            }));
    }

    return (
      <div className={`IImageMapper ${className ? className : ""}`}>
          <svg
            className={src === sources.spineSVG ? "Isvges" : "Ipnges"}
            ref={this.refSvg}
            height={350}
          >
            {svgContent()}
          </svg>
      </div>
    );
  }
}

export default ILeletImageMapper;
