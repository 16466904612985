import * as React from "react";

function Body(props) {
  return (
    <g id="body_background">
      <defs
         id="defs2122">
        <linearGradient
           id="paint0_linear_1108_1166"
           x1="76.5"
           y1="0"
           x2="76.5"
           y2="50"
           gradientUnits="userSpaceOnUse"
           gradientTransform="matrix(0.70333654,0,0,1,-0.00653412,0.53753045)">
          <stop
             stop-color="#F4F5F8"
             id="stop2896" />
          <stop
             offset="1"
             stop-color="#F4F5F8"
             stop-opacity="0"
             id="stop2898" />
        </linearGradient>
        <linearGradient
           id="paint0_linear_1108_1166-3"
           x1="76.5"
           y1="0"
           x2="76.5"
           y2="50"
           gradientUnits="userSpaceOnUse"
           gradientTransform="translate(-309.21546,70.830844)">
          <stop
             stop-color="#F4F5F8"
             id="stop2896-6" />
          <stop
             offset="1"
             stop-color="#F4F5F8"
             stop-opacity="0"
             id="stop2898-7" />
        </linearGradient>
        <linearGradient
           id="paint0_linear_1108_1167"
           x1="229.5"
           y1="50"
           x2="229.5"
           y2="100"
           gradientUnits="userSpaceOnUse"
           gradientTransform="matrix(0.69968397,0,0,1,-214.34416,-403.47053)">
          <stop
             stop-color="#F4F5F8"
             id="stop5328" />
          <stop
             offset="1"
             stop-color="#F4F5F8"
             stop-opacity="0"
             id="stop5330" />
        </linearGradient>
      </defs>
      <path
         d="M103.841 188.649C106.808 194.572 103.098 223.827 105.323 250.118C107.547 276.409 90.4938 321.217 89.3796 324.181C88.2655 327.144 88.6369 354.033 88.6369 354.033H5.33368C-10.7952 321.351 18.239 284.818 20.6566 279.18C23.0743 273.542 27.5122 263.071 14.6069 194.628C10.2915 171.742 9.16998 147.269 16.1704 124.772C17.6448 120.039 19.4868 115.41 21.7893 111.014C22.6323 109.408 23.6016 107.891 24.4966 106.319C25.9859 103.707 28.203 101.634 30.0376 99.2784C31.9353 96.8451 32.8637 94.0112 33.5582 91.0216C34.9249 85.1462 36.7297 76.244 35.0029 70.4464C33.3577 64.9123 27.3785 61.2624 24.5895 56.318C21.3214 50.5241 18.6029 46.3586 17.6968 39.7191C15.294 22.1299 25.9302 8.53923 42.7386 2.60074C55.2874 -1.8318 70.5323 -0.0476554 81.4693 7.65644C89.1754 13.0831 96.0718 23.4355 94.9057 33.2947C94.6383 35.5499 93.7804 37.0225 94.3115 39.3259C94.8797 41.7889 96.4655 43.8141 97.379 46.1324C98.2703 48.4024 101.438 53.0241 101.033 55.0531C100.629 57.0821 98.8423 57.3232 98.8423 57.3232H95.1062C94.9503 58.2171 95.032 59.1555 95.2251 60.0346C95.5259 61.3996 96.3838 62.4419 95.9567 63.9145C95.6781 64.8678 94.9688 65.6875 94.0627 66.0992C93.9253 66.1623 93.7693 66.2253 93.7024 66.3626C93.6504 66.4739 93.6727 66.6037 93.6913 66.7261C94.1332 69.6786 91.5522 71.83 91.3739 74.6787C91.2105 77.2752 93.5427 78.7477 90.063 81.8153C86.5795 84.8791 84.7337 83.4473 76.9497 83.2433C69.1657 83.0393 71.8284 93.4734 71.8284 93.4734C71.8284 93.4734 77.1502 106.812 96.0569 137.918C114.964 169.023 103.841 188.649 103.841 188.649Z"
         fill="white"
         id="body" />
      <path
         d="M -0.00653432,0.53753045 H 107.60396 V 50.53753 H -0.00653432 Z"
         fill="url(#paint0_linear_1108_1166)"
         id="top_box"/>
      <rect
         x="-107.29252"
         y="-353.47055"
         width="107.05165"
         height="50"
         transform="scale(-1)"
         fill="url(#paint0_linear_1108_1167)"
         id="bottom_box"/>
    </g>
  );
}

const MemoBody = React.memo(Body);
export default MemoBody;
