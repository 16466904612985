export const getOffset = (ref, ancestor = "body") => {
  const offset = { right: ref.offsetLeft+ref.offsetWidth, top: ref.offsetTop };
  const offsetParent = ref.offsetParent;

  if (ref.id === ancestor || ref.className === ancestor || offsetParent === null) {
    return { right: 0, top: 0 };
  }

  const parentOffset = getOffset(offsetParent, ancestor);
  offset.top += parentOffset.top;
  offset.right += parentOffset.right;
  return offset;
}

export const getOffsetAndPositions = (ref, ancestor = "body") => {
  const offset = getOffset(ref, ancestor);
  const height = ref.offsetHeight;
  const width = ref.offsetWidth;
  return {
    width,
    height,
    top: offset.top,
    left: offset.right,
    bot: offset.top + height,
    right: offset.right,
    vCenter: offset.top + height / 2,
    hCenter: offset.left + width / 2
  }
}