import "styles/antd.app.css";
import "./App.scss";

import React, { useEffect, useState } from "react";
import Graphics from "components/Graphics";
import { Analysis, AnalysisResponse, Entity, prepare, toAnalysis } from "types/analysis";
import { DEMO_ANALYSIS } from "./demo";
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import html2canvas from "html2canvas";


type State = {
  highlight: Entity[];
  autoHighlight: boolean;
  analysis: Analysis;
};

const App: React.FC = () => {
  const [state, setState] = useState<State>({
    highlight: [],
    autoHighlight: false,
    analysis: {entities:[],annotations:{entities:[],relations:[]},ann:""}
  });

  const [highlightedEntity, setHighlightedEntity] = useState<Entity|null>(null);

  useEffect(() => {
    window.addEventListener("message", async (event) => {
      if ("entities" in event.data && "ann" in event.data && "annotations" in event.data && "tags" in event.data)
        setState({
          ...state,
          analysis: toAnalysis(event.data as unknown as AnalysisResponse),
        });
      else if ("pictureRequest" in event.data && event.data.pictureRequest) {
        const element = document.getElementById('print');
        const canvas = await html2canvas(element as HTMLElement);
        const data = canvas.toDataURL('image/jpg');
        window.parent.postMessage({imageData: data}, "*" );
      }
    });

    return () => {
      window.removeEventListener("message", () => {});
    }
  }, []);

  return (
      <div className={`AnalysisPage__graphics page-card`}>
        <Graphics
          analysis={state.analysis.entities}
          updateHighlight={(analysis: Entity[]) =>
            setState({ ...state, highlight: analysis })
          }
          analysisLoading={!false}
          fileId="analyser"
          mobileHighlight={setHighlightedEntity}
        />
        {(highlightedEntity && highlightedEntity.video.length > 1) && <Button type="primary" id="wiki__btn" onClick={()=>window.parent.postMessage(highlightedEntity, "*")}>Tovább a tudástárra <RightOutlined /></Button>}
      </div>
  );
};

export default App;
